import React, { useMemo, useState } from 'react'
import {
  DayOfApplicableType,
  DayOfUseType,
  StockPerReservationType,
  TimeSelectionDetail,
  TimeSelectionInput,
} from '@/models/custom-checkin-time-selection'
import { UseFormReturn, Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Editor } from '@/components/organisms/settings/editor'
import { Select } from '@/components/atoms/select'
import { Hint } from '@/components/atoms/hint'
import { RHFInputField } from '@/components/molecules/RHFInput-field'
import { Checkbox } from '@/components/atoms/checkbox'
import { css } from '@emotion/core'
import { SpaceDetailType } from '@/models/guest-app/asset'
import { AddFormFieldButton } from '@/components/molecules/add-form-field-button'
import { KeywordGroup } from '@/models/reservation/keyword-group'

interface DetailFormProps {
  useFormReturn: UseFormReturn<TimeSelectionInput>
  spaceAssets: SpaceDetailType[]
  keyWordGroups: KeywordGroup[]
  detail?: TimeSelectionDetail
}

export const DetailForm: React.FC<DetailFormProps> = ({ useFormReturn, spaceAssets, keyWordGroups, detail }) => {
  const [selectedSpaceId, setSelectedSpaceId] = useState<string | undefined>(detail?.spaceId)
  const [selectedKeyWordGroupId, setSelectedKeyWordGroupId] = useState<string | undefined>(detail?.planKeyWordGroupId)
  const [isAllPlanKeywords, setIsAllPlanKeywords] = useState<boolean>(false)

  const { t } = useTranslation()

  const { control, setValue, watch } = useFormReturn
  const watchDescription = watch('description')
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'planKeywords',
    rules: { required: selectedKeyWordGroupId ? t('Required field has not been entered') : false },
  })

  const onChangeSpaceType = (selectedValue: string) => {
    const selectedSpace = spaceAssets.find(space => space.id === selectedValue)
    if (!selectedSpace) {
      return
    }

    setValue('title', selectedSpace.title)
    setValue('description', selectedSpace.description)
    setValue('rawDescription', '{}')
    setSelectedSpaceId(selectedSpace.id)
  }

  const onChangeKwyWordGroup = (selectedValue: string) => {
    setSelectedKeyWordGroupId(selectedValue as string)
    setIsAllPlanKeywords(true)
    remove()

    const keywords = keyWordGroups.find(group => group.id === selectedValue)?.keywords || []
    append(keywords.map(({ keyword }) => ({ keyword })))
  }

  const selectedAllPlanKeywords = useMemo(() => {
    if (!selectedKeyWordGroupId) {
      return []
    }

    const keywords = keyWordGroups.find(group => group.id === selectedKeyWordGroupId)?.keywords || []
    return keywords.map(({ keyword }) => ({ keyword }))
  }, [selectedKeyWordGroupId])

  return (
    <div css={detailFormStyle}>
      <div className="left-block">
        <div css={inputTitleTextStyle}>
          <p>
            {t('Space type')}
            <span css={requireLabelTextStyle}>※</span>
          </p>
          <Hint
            customCss={css({
              paddingLeft: 8,
              position: 'static',
              '.help-image': {
                width: 268,
              },
            })}
            src={require('@/static/images/time-selection/hint_image_space.png')}
          ></Hint>
        </div>
        <div css={inputAreaStyle}>
          <Controller
            name="spaceId"
            control={control}
            rules={{ required: t('Required field has not been entered') }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                customStyle={css({ width: 518, paddingBottom: 0 })}
                value={value}
                onChange={v => {
                  onChangeSpaceType(v as string)
                  onChange(v)
                }}
              >
                <option value="">{t('PleaseSelect')}</option>
                {spaceAssets.map((space, index) => (
                  <option key={space.id} value={space.id}>
                    {space.title}
                  </option>
                ))}
              </Select>
            )}
          />
        </div>

        <div css={[inputTitleTextStyle, { marginTop: 24 }]}>
          <p>
            {t('Title')}
            <span css={requireLabelTextStyle}>※</span>
          </p>
        </div>
        <div css={[inputAreaStyle, { alignItems: 'center', gap: 8 }]}>
          <RHFInputField control={control} name="title" placeholder={t('Dinner time')} marginBottom={0} />
        </div>

        <div css={[inputTitleTextStyle, { marginTop: 24 }]}>
          <p>{t('Content')}</p>
        </div>
        <div>
          <Controller
            control={control}
            name="rawDescription"
            render={({ field: { value } }) => {
              return (
                <Editor
                  id={selectedSpaceId}
                  placeholder={t('Please select a time for dinner')}
                  htmlContent={watchDescription}
                  rawContent={value}
                  onChange={(raw: string, html: string) => {
                    setValue('description', html, { shouldDirty: true })
                    setValue('rawDescription', raw, { shouldDirty: true })
                  }}
                  style={{ height: 343 }}
                />
              )
            }}
          />
        </div>
      </div>

      <div className="right-block">
        <div css={inputTitleTextStyle}>
          <p>
            {t('keyword group')}
            <span css={requireLabelTextStyle}>※</span>
          </p>
        </div>
        <div>
          <Controller
            name="planKeyWordGroupId"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                value={value}
                onChange={v => {
                  onChangeKwyWordGroup(v as string)
                  onChange(v)
                }}
                customStyle={css({ width: 518, paddingBottom: 0 })}
              >
                <option value="">{t('All reservations')}</option>
                {keyWordGroups.map((group, index) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Select>
            )}
          />
        </div>

        {selectedKeyWordGroupId && (
          <>
            <div css={[inputTitleTextStyle, { marginTop: 24 }]}>
              <p>{t('Keywords to display')}</p>
            </div>

            {isAllPlanKeywords && (
              <Select
                value="all"
                onChange={v => {
                  if (v === 'all') {
                    setIsAllPlanKeywords(true)
                    append(selectedAllPlanKeywords)
                    return
                  }

                  setIsAllPlanKeywords(false)
                  remove()
                  append({ keyword: v as string })
                }}
                customStyle={css({ width: 518, paddingBottom: 0 })}
              >
                <option value="all">{t('All keywords')}</option>
                {selectedAllPlanKeywords.map((planKeyword, index) => (
                  <option key={`${planKeyword.keyword}.${index}`} value={planKeyword.keyword}>
                    {planKeyword.keyword}
                  </option>
                ))}
              </Select>
            )}

            {!isAllPlanKeywords &&
              fields.map((field, index) => {
                return (
                  <div key={field.id} style={{ display: 'flex', alignItems: 'center', marginTop: index === 0 ? 0 : 16 }}>
                    <Controller
                      name={`planKeywords.${index}.keyword`}
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Select
                          value={value}
                          onChange={v => {
                            if (v === 'all') {
                              remove()
                              append(selectedAllPlanKeywords)
                              setIsAllPlanKeywords(true)
                              return
                            }

                            update(index, { keyword: v as string })
                            onChange(v)
                          }}
                          customStyle={css({ width: 518, paddingBottom: 0 })}
                        >
                          <option value="all">{t('All keywords')}</option>
                          {selectedAllPlanKeywords.map((planKeyword, index) => (
                            <option key={`${planKeyword.keyword}.${index}`} value={planKeyword.keyword}>
                              {planKeyword.keyword}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    {field.keyword !== 'all' && index !== 0 && (
                      <img
                        src={require('@/static/images/deleat.svg')}
                        css={deleteStyle}
                        width="24"
                        height="24"
                        onClick={() => remove(index)}
                      />
                    )}
                  </div>
                )
              })}
            {selectedAllPlanKeywords.length !== fields.filter(({ keyword }) => keyword).length && (
              <div style={{ marginTop: 16 }}>
                <AddFormFieldButton
                  label={t('Add keywords to display')}
                  onAdd={() => {
                    append({ keyword: '' })
                  }}
                />
              </div>
            )}
          </>
        )}

        <div style={{ display: 'flex', gap: 18, marginTop: 24 }}>
          <div>
            <div css={inputTitleTextStyle}>
              <p>
                {t('Effective date')}
                <span css={requireLabelTextStyle}>※</span>
              </p>
              <Hint
                customCss={css({
                  paddingLeft: 8,
                  position: 'static',
                  '.help-popup': {
                    p: {
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 24,
                      padding: 16,
                    },
                    span: {
                      lineHeight: 1.5,
                      whiteSpace: 'pre-wrap',
                    },
                  },
                })}
              >
                <p>
                  <span>{t('You can specify the dates from which you can select the time.')}</span>
                  <span>
                    {t(
                      '◼︎every day \n1st night : dinner time (18:00, 18:30, 19:00)\n2nd night : dinner time (18:00, 18:30, 19:00)\n3rd night : dinner time (18:00, 18:30, 19:00)',
                    )}
                  </span>
                  <span>{t('◼︎1 for night only 1st night : Dinner time (18:00, 18:30, 19:00)．n2nd night : None\n3rd night : None')}</span>
                  <span>
                    {t('◼︎For the last night only \n1st night : None\n2nd night : None\n3rd night : Dinner time (18:00, 18:30, 19:00)')}
                  </span>
                </p>
              </Hint>
            </div>
            <div css={[inputAreaStyle, { alignItems: 'center', gap: 8 }]}>
              <Controller
                name="dayOfApplicable"
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select value={value} onChange={onChange} customStyle={css({ width: 250, paddingBottom: 0 })}>
                    <option value={DayOfApplicableType.EveryDay}>{t('Every day')}</option>
                    <option value={DayOfApplicableType.FirstNight}>{t('First night only')}</option>
                    <option value={DayOfApplicableType.LastDay}>{t('Last night only')}</option>
                  </Select>
                )}
              />
            </div>
          </div>

          <div>
            <div css={inputTitleTextStyle}>
              <p>
                {t('Date used')}
                <span css={requireLabelTextStyle}>※</span>
              </p>
            </div>
            <div css={[inputAreaStyle, { alignItems: 'center', gap: 8 }]}>
              <Controller
                name="dayOfUse"
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select value={value} onChange={onChange} customStyle={css({ width: 250, paddingBottom: 0 })}>
                    <option value={DayOfUseType.AppointedDay}>{t('Appointed day')}</option>
                    <option value={DayOfUseType.NextDay}>{t('Next day')}</option>
                  </Select>
                )}
              />
            </div>
          </div>
        </div>

        <div css={[inputTitleTextStyle, { marginTop: 24 }]}>
          <p>
            {t('Number of spaces per reservation')}
            <span css={requireLabelTextStyle}>※</span>
          </p>
          <Hint
            customCss={css({
              paddingLeft: 8,
              position: 'static',
              '.help-image': {
                width: 268,
              },
            })}
            src={require('@/static/images/time-selection/hint_image_per_reservation.png')}
          ></Hint>
        </div>
        <div css={[inputAreaStyle, { alignItems: 'center', gap: 8, width: 250 }]}>
          <Controller
            name="stockPerReservation"
            control={control}
            rules={{ required: t('Required field has not been entered') }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select value={value} onChange={onChange} customStyle={css({ width: 518, paddingBottom: 0 })}>
                <option value={StockPerReservationType.Single}>{1}</option>
                <option value={StockPerReservationType.NumOfPeople}>{t("some people's portion")}</option>
              </Select>
            )}
          />
        </div>

        <div style={{ marginTop: 24 }}>
          <p style={{ marginBottom: 12, fontSize: 12, fontWeight: 'bold' }}>{t('Answer')}</p>
          <Controller
            control={control}
            name="isRequired"
            render={({ field: { value, onChange } }) => (
              <Checkbox value={value} label={t('Required')} onChange={onChange} style={css({ width: '100%', height: '100%' })} />
            )}
          />
        </div>
      </div>
    </div>
  )
}

const detailFormStyle = css({
  display: 'flex',
  padding: 32,
  '> div': {
    width: '48.2%',
  },
  '.left-block': {
    paddingRight: 32,
    marginRight: 32,
    borderRight: '1px solid #f2f2f2',
  },
})

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
  alignItems: 'center',
})

const inputAreaStyle = css({
  display: 'flex',
  '.unit': {
    fontSize: 12,
    letterSpacing: 0.6,
    color: '#676767',
    paddingRight: 8,
    lineHeight: '32px',
  },
})

const requireLabelTextStyle = css({
  position: 'relative',
  top: -3,
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 4,
})

const deleteStyle = css({
  width: 24,
  marginLeft: 16,
  cursor: 'pointer',
})
