import { SerializedStyles } from '@emotion/core'
import React from 'react'

interface RequireTextProps {
  customCss?: SerializedStyles
}

export const RequireText: React.FC<RequireTextProps> = ({ customCss }) => {
  return <span css={[{ color: '#f2a40b', fontSize: 14 }, customCss]}>※</span>
}
