import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'

//components
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'

type Props = {
  onCancel: () => void
  onShutter: (image: string) => void
  type: 'license' | 'passport'
  side?: 'front' | 'back'
}

export const IdentityVerifyCamera: React.FC<Props> = props => {
  const [mediaStream, setMediaStream] = useState<MediaStream>()
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>()
  const { t } = useTranslation()
  const canvas = document.querySelector('canvas')
  if (canvas !== null) {
    canvas.width = 757
    canvas.height = props.type === 'license' ? 454 : 493
  }

  useEffect(() => {
    async function enableStream() {
      try {
        // Option setting of getUserMedia to match the screen size
        const constraints = {
          audio: false,
          video: {
            width: { min: 957, ideal: window.outerWidth, max: 1920 },
            height: { min: props.type === 'license' ? 594 : 694, ideal: window.outerHeight, max: 1080 },
            aspectRatio: window.outerWidth / window.outerHeight,
            facingMode: 'user',
          },
        }

        const _videoElement = document.querySelector<HTMLVideoElement>('video')
        setVideoElement(_videoElement)
        if (_videoElement) {
          _videoElement.addEventListener('loadedmetadata', () => {
            // Video sizing to match device camera
            const ratio = window.outerHeight / _videoElement.videoHeight
            _videoElement.width = _videoElement.videoWidth * ratio
            _videoElement.height = window.outerHeight
          })
        }

        const stream = await navigator.mediaDevices.getUserMedia(constraints)

        if (_videoElement) {
          _videoElement.srcObject = stream
        }
        setMediaStream(stream)
      } catch (error) {
        console.log(error)
      }
    }
    if (!mediaStream) {
      enableStream()
    }
  }, [])

  useEffect(() => {
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach(track => {
          track.stop()
        })
      }
    }
  }, [mediaStream])

  const onClickGetPicture = (): void => {
    if (canvas === null || videoElement === null) return
    const ctx = canvas.getContext('2d')
    if (ctx === null || !videoElement) return
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height)

    const url = canvas.toDataURL('image/jpeg')
    // camera stop
    if (mediaStream === null || mediaStream === undefined) return
    mediaStream.getTracks().forEach(track => track.stop())

    props.onShutter(url)
  }

  const windows = useWindowSize()

  return (
    <>
      <div
        css={videoWrapper}
        style={{
          width: windows.width,
          height: window.innerHeight - 90,
        }}
      >
        <video playsInline autoPlay />
        {props.type === 'passport' ? (
          <div css={frameTextWrapperStyle} style={{ height: 493 }}>
            <div css={frameTextPassportStyle}>{t('Please align your passport with the frame on the screen')}</div>
          </div>
        ) : props.type === 'license' && props.side === 'front' ? (
          <div css={frameTextWrapperStyle} style={{ height: 454 }}>
            <div css={frameTextLicenseStyle}>{t('Please take a picture by aligning the surface with the frame')}</div>
          </div>
        ) : (
          <div css={frameTextWrapperStyle} style={{ height: 454 }}>
            <div css={frameTextLicenseStyle}>{t('Please take a picture by aligning the back side within the frame')}</div>
          </div>
        )}
        <canvas css={videoframeWrapper} />
        <SelfCheckinFooter
          isDisabled={() => false}
          isDisabledButton={true}
          goToPreviousPage={props.onCancel}
          goToNextPage={() => onClickGetPicture()}
          isNext={'shoot'}
        />
      </div>
    </>
  )
}

const videoWrapper = css({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  '*': {
    fontFamily: 'Noto Sans JP',
  },
})

const frameTextStyle = css({
  color: '#fff',
  fontSize: 20,
  fontWeight: 'bold',
  lineHeight: '29px',
  position: 'absolute',
  left: '50%',
  margin: 'auto',
  textAlign: 'center',
})

const frameTextPassportStyle = css(frameTextStyle, {
  top: '5.5%',
  '-webkit-transform': 'translate(-50%,-5.5%)',
  '-ms-transform': 'translate(-50%,-5.5%)',
  transform: 'translate(-50%,-5.5%)',
})

const frameTextLicenseStyle = css(frameTextStyle, {
  top: '6.5%',
  '-webkit-transform': 'translate(-50%,-6.5%)',
  '-ms-transform': 'translate(-50%,-6.5%)',
  transform: 'translate(-50%,-6.5%)',
})
const videoframeWrapper = css({
  borderRadius: 13,
  border: '3px solid #fff',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '-webkit-transform': 'translate(-50%, -50%)',
  '-ms-transform': 'translate(-50%, -50%)',
  margin: 'auto',
})

const frameTextWrapperStyle = css({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  margin: 'auto',
  width: '100%',
})
