import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

export const SuccessDialog: React.FC<{}> = () => {
  const { t } = useTranslation()

  return (
    <>
      <div css={backgroundStyle}></div>
      <div css={dialogStyle}>
        <div className="content-block">
          <img src={require('@/static/images/self-checkin-payment/terminal/icon_check.svg')} />
          <div className="-text" style={{ paddingTop: 24 }}>
            {t('Payment has been completed')}
          </div>
        </div>
      </div>
    </>
  )
}

const backgroundStyle = css({
  background: 'rgba(0, 0, 0, 0.5)',
  height: '100vh',
  width: '100vw',
  position: 'absolute',
  zIndex: 1000,
  top: 0,
  left: 0,
})
const dialogStyle = css({
  position: 'absolute',
  zIndex: 1001,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: '#FFFFFF',
  borderRadius: 5,
  '.content-block': {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    padding: '40px 70px',
  },
  '.-text': {
    fontSize: 21,
    fontWeight: 'bold',
    letterSpacing: 1.05,
    color: '#272727',
  },
})
