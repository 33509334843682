import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { SelfCheckinBasicInfoPluginType } from '@/models/self-checkin/results'

type TabProps = {
  currentTab: 'license' | 'passport'
}

export const Tab: React.FC<TabProps> = ({ currentTab }) => {
  const history = useHistory()
  const { search, state } = useLocation<{
    basicInfo: SelfCheckinBasicInfoPluginType
    identificationFrontImage: string
    identificationBackImage: string
  }>()
  const { t } = useTranslation()

  return (
    <div css={settingTabContainerStyle}>
      <div
        css={currentTab === 'license' ? settingTabActiveStyle : settingTabStyle}
        onClick={() => history.replace({ pathname: '/self-checkin/identity-verify/upload-license', search, state: { ...state } })}
      >
        {t('Japanese')}
      </div>
      <div
        css={currentTab === 'passport' ? settingTabActiveStyle : settingTabStyle}
        onClick={() => history.replace({ pathname: '/self-checkin/identity-verify/upload-passport', search, state: { ...state } })}
      >
        {t('Non-Japanese')}
      </div>
    </div>
  )
}
const centerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const settingTabContainerStyle = css({
  height: 70,
  display: 'flex',
  width: '100%',
  maxWidth: 874,
  '@media(max-width: 1080px)': {
    height: 61,
  },
})

const settingTabStyle = css(centerStyle, {
  width: '50%',
  height: 61,
  borderRadius: '5px 5px 0 0',
  background: '#F5F5F5',
  fontSize: 18,
  fontWeight: 'bold',
  color: '#676767',
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.8,
  },
})

const settingTabActiveStyle = css(centerStyle, {
  width: '50%',
  height: 70,
  borderRadius: '5px 5px 0 0',
  background: '#fff',
  boxShadow: '0px 0px 6px #0000001A',
  position: 'relative',
  fontWeight: 'bold',
  fontSize: 18,
  color: '#f2a40b',
  '&::after': {
    position: 'absolute',
    bottom: -4,
    left: 0,
    height: 6,
    width: '100%',
    content: '""',
    background: '#fff',
    zIndex: 1,
  },
  '@media(max-width: 1080px)': {
    height: 61,
    '&::after': {
      bottom: -11,
      left: 0,
      height: 12,
      width: '100%',
      content: '""',
      background: '#fff',
      zIndex: 1,
    },
  },
})
