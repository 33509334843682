import React, { HTMLAttributes } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

export const SupportQr: React.FC<HTMLAttributes<HTMLDivElement>> = props => {
  const { t } = useTranslation()

  return (
    <div css={blockStyle} {...props}>
      <div className="payment-title">{t('Supported QR Payment')}</div>
      <img src={require('@/static/images/self-checkin-payment/terminal/logo_qr01.png')} />
      <div className="qr-sub-image-block">
        <img src={require('@/static/images/self-checkin-payment/terminal/logo_qr02.png')} />
        <img src={require('@/static/images/self-checkin-payment/terminal/logo_qr03.png')} />
      </div>
    </div>
  )
}

const blockStyle = css({
  border: '1px solid #CCCCCC',
  borderRadius: 10,
  '.payment-title': {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 1.8,
    color: '#676767',
    paddingTop: 8,
    lineHeight: 1.5,
  },
  '> img': {
    width: '100%',
    padding: 6,
  },
  '.qr-sub-image-block': {
    display: 'flex',
    borderTop: '1px solid #CCCCCC',
    'img:first-of-type': { width: '40%', padding: 6, borderRight: '1px solid #CCCCCC' },
    'img:last-of-type': { width: '60%', padding: 6 },
  },
})
