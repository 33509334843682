import React, { ComponentProps, useEffect, useRef } from 'react'
import { css } from '@emotion/core'
import { draggableIconStyle } from '@/components/molecules/settings/use-draggable-dom'

export const TableHeader: React.FC<ComponentProps<'div'>> = ({ children, ...props }) => {
  return (
    <div id="table-header" css={tableHeaderStyle} {...props}>
      {children}
    </div>
  )
}
export const TableBody: React.FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const clientHeight = ref?.current?.clientHeight || 0
    const scrollHeight = ref?.current?.scrollHeight || 0
    const headerDom = document.getElementById('table-header')
    if (clientHeight < scrollHeight && headerDom) {
      headerDom.style.overflowY = 'scroll'
    }
  }, [ref])
  return (
    <div ref={ref} css={tableBodyStyle}>
      {children}
    </div>
  )
}
export const TableRow: React.FC<ComponentProps<'div'>> = ({ css, children, ...props }) => {
  return (
    <div css={[tableRowStyle, css as any, props.draggable && draggableIconStyle]} {...props}>
      {children}
    </div>
  )
}

const tableHeaderStyle = css({
  height: 32,
  background: '#F2F2F2',
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  '> div': {
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: '1.2px',
    color: '#676767',
  },
  zIndex: 10,
})

const tableBodyStyle = css({
  overflow: 'auto',
  height: '100%',
})

const tableRowStyle = css([
  {
    display: 'flex',
    minHeight: 62,
    alignItems: 'center',
    '> div': {
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: '0.56px',
      color: '#272727',
    },
    borderBottom: '1px solid #F2F2F2',
    cursor: 'pointer',
    position: 'relative',
  },
])
