import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { SelfCheckinHeader } from '@/components/molecules'
import { Tab as IdentityVerifyUploadTab } from '@/components/organisms/self-checkin/identity-verify/tab'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { Loading } from '@/components/molecules/self-checkin/loading'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { IdentityVerifyCamera } from '@/components/organisms/self-checkin/identity-verify/identity-verify-camera'
import { NationalityType } from '@/models/self-checkin/accommodation-info'
import { SelfCheckinLayout } from '@/components/layouts/self-checkin-layout'

type ContainerProps = {
  title: string
}
export const UploadPassport: React.FC<ContainerProps> = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const lang = i18n.language
  const { state, saveState } = useSelfCheckInState()
  const [representativePassportImage, setRepresentativePassportImage] = useState<string>()
  const [companionImageArray, setCompanionImageArray] = useState<
    {
      id: number
      companionPassportImage: string
    }[]
  >([])
  const [activateCameraCompanionIndex, setActivateCameraCompanionIndex] = useState<number>()
  const [companionNumber, setCompanionNumber] = useState<number>(
    (state.accommodationInfo?.paxTotal || state.selectReservation?.paxTotal || 1) - 1,
  )
  const [buttonName] = useState<'submit' | 'next' | 'edit' | 'checkin'>('next')
  const [isLoading] = useState<boolean>(false)
  const [activateCameraType, setActivateCameraType] = useState<'representativePassport' | 'companionPassport' | undefined>(undefined)
  const [isInitializeComponent, setIsInitializeComponent] = useState(false)
  const selectNumbers: number[] = []
  for (let i = 0; i <= 50; i++) {
    selectNumbers.push(i)
  }

  const uploadImage = (imageType: 'representativePassport' | 'companionPassport', index?: number): void => {
    setActivateCameraType(imageType)
    setActivateCameraCompanionIndex(index)
  }

  const changeNumberOfPeople = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setCompanionNumber(Number(value))
  }

  const onShutter = (image: any) => {
    if (activateCameraType === 'representativePassport') {
      setRepresentativePassportImage(image)
    } else if (activateCameraCompanionIndex !== undefined) {
      const photoInState = companionImageArray.findIndex(v => v.id === activateCameraCompanionIndex)
      if (photoInState !== -1) {
        companionImageArray[photoInState].companionPassportImage = image
        setCompanionImageArray([...companionImageArray])
      } else {
        setCompanionImageArray([
          ...companionImageArray,
          {
            id: activateCameraCompanionIndex,
            companionPassportImage: image,
          },
        ])
      }
    }
    setActivateCameraType(undefined)
  }

  const isDisabled = (): boolean => {
    if (representativePassportImage && companionNumber === 0) {
      return false
    }

    if (representativePassportImage && companionImageArray.length) {
      for (let index = 0; index < companionNumber; index++) {
        const image = companionImageArray.find(img => img.id === index)
        if (image) continue

        return true
      }

      return false
    }

    return true
  }

  const submit = () => {
    saveState({
      ...state,
      uploadLicense: {
        representativePassportImage,
        companionImageArray,
      },
    })
    history.push({ pathname: '/self-checkin/confirm' })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!state?.hotelId) {
      history.replace({ pathname: '/dashboard' })
      return
    }

    const isForeign = state.accommodationInfo?.nationality !== NationalityType.Jp
    const isRequiredPassport = isForeign && state.setting.accommodation.requiredForeignIdentify
    const isRequiredLicense = !isForeign && state.setting.accommodation.requiredJapaneseIdentify
    if (!isRequiredPassport && !isRequiredLicense) {
      history.replace({ pathname: '/self-checkin/confirm' })
      return
    }
    setIsInitializeComponent(true)
    if (state.uploadLicense?.representativePassportImage) {
      setRepresentativePassportImage(state.uploadLicense.representativePassportImage)
    }
    if (state.uploadLicense?.companionImageArray) {
      setCompanionImageArray(state.uploadLicense.companionImageArray)
    }
  }, [])

  if (!isInitializeComponent) {
    return <></>
  }
  return (
    <SelfCheckinLayout>
      <Loading isLoading={isLoading} />
      {!activateCameraType && (
        <div css={containerStyle}>
          <SelfCheckinHeader goToPreviousPage={history.goBack} title={t('Identification')} />
          <div css={settingMainContainerStyle}>
            <div css={mainStyle}>
              <div css={settingHeaderContainerStyle}>
                <IdentityVerifyUploadTab currentTab="passport" />
              </div>
              <div css={settingListContainerStyle}>
                <div css={descriptionStyle}>{t('Please upload your passport')}</div>
                <div css={uploadWrapperStyle}>
                  <div css={imageTitleStyle}>{t('Representative')}</div>
                  <div css={uploadItemStyle} onClick={() => uploadImage('representativePassport')}>
                    <div css={representativePassportImage ? withBackgroundStyle : undefined}>
                      <img
                        css={identificationImageStyle}
                        src={representativePassportImage ? representativePassportImage : require('@/static/images/passport.svg')}
                        alt={t('Check-in with Identification')}
                      />
                    </div>
                    <div className={representativePassportImage ? 'uploaded' : ''} css={uploadItemTextStyle}>
                      <div>
                        <img
                          src={
                            representativePassportImage
                              ? require('@/static/images/camera_icon_white.svg')
                              : require('@/static/images/camera_icon_orange.svg')
                          }
                          alt={t('Take a Photo')}
                        />
                        <p>{t('Take a Photo')}</p>
                      </div>
                    </div>
                  </div>
                  <div css={imageTitleStyle}>{t('Companion')}</div>
                  <div css={numberOfPeopleWrapperStyle}>
                    <select name="companion" css={largeSelectBoxStyle} value={companionNumber} onChange={e => changeNumberOfPeople(e)}>
                      {selectNumbers.map((i, index) => (
                        <option value={i} key={index}>
                          {i}
                        </option>
                      ))}
                    </select>
                    {!(lang === 'en' && companionNumber > 1) && <div css={numberOfPeopleStyle}>{t('Person')}</div>}
                    {lang === 'en' && companionNumber > 1 && <div css={numberOfPeopleStyle}>{t('Persons')}</div>}
                  </div>
                  <div css={companionNumber % 3 === 2 ? leftCompanionPassportImageWrapperStyle : companionPassportImageWrapperStyle}>
                    {new Array(companionNumber).fill(null).map((_, i) => {
                      const image = companionImageArray.find(img => img.id == i)
                      return (
                        <div key={i} style={{ marginBottom: '16px' }}>
                          <div css={uploadItemStyle} onClick={() => uploadImage('companionPassport', i)} key={`companion${i}`}>
                            <div css={companionImageArray.length && image ? withBackgroundStyle : undefined}>
                              <img
                                css={identificationImageStyle}
                                src={
                                  companionImageArray.length && image
                                    ? image.companionPassportImage
                                    : require('@/static/images/passport.svg')
                                }
                                alt={t('Check-in with Identification')}
                              />
                            </div>
                            <div className={companionImageArray.length && image ? 'uploaded' : ''} css={uploadItemTextStyle}>
                              <div>
                                <img
                                  src={
                                    companionImageArray.length && image
                                      ? require('@/static/images/camera_icon_white.svg')
                                      : require('@/static/images/camera_icon_orange.svg')
                                  }
                                  alt={t('Take a Photo')}
                                />
                                <p>{t('Take a Photo')}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SelfCheckinFooter isDisabled={isDisabled} goToNextPage={submit} isNext={buttonName} />
        </div>
      )}
      {activateCameraType && (
        <IdentityVerifyCamera onCancel={() => setActivateCameraType(undefined)} onShutter={onShutter} type="passport" />
      )}
    </SelfCheckinLayout>
  )
}

const descriptionStyle = css({
  paddingTop: 50,
  color: '#272727',
  fontWeight: 'bold',
  fontSize: 20,
  lineHeight: '24px',
  letterSpacing: '3.4px',
  textAlign: 'center',
  '@media(max-width: 1080px)': {
    fontSize: 20,
    lineHeight: '35px',
    letterSpacing: '3px',
  },
})

const imageTitleStyle = css({
  fontSize: 18,
  marginBottom: 16,
  marginTop: 40,
  fontWeight: 'bold',
  color: '#272727',
  '@media(max-width: 1080px)': {
    fontSize: 18,
    marginBottom: 14,
    marginTop: 38,
  },
})

const uploadWrapperStyle = css({
  margin: '0 auto',
  padding: '0 60px',
  display: 'block',
})

const uploadItemStyle = css({
  width: 246,
  height: 172,
  cursor: 'pointer',
  color: '#f2a40b',
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: '2.7px',
  lineHeight: '27px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '3px',
  '@media(max-width: 880px)': {
    width: 224,
    margin: '0 4px',
  },
  '@media(max-width: 800px)': {
    width: 210,
    margin: '0 3px',
  },
})

const withBackgroundStyle = css({
  position: 'relative',
  '&::after': {
    content: '""',
    height: 172,
    width: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    top: 0,
    left: 0,
    position: 'absolute',
    borderRadius: 10,
  },
  '@media(max-width: 1080px)': {
    '&::after': {
      height: 172,
    },
  },
})

const identificationImageStyle = css({
  width: 246,
  height: 172,
  borderRadius: 8,
  '@media(max-width: 880px)': {
    width: 224,
  },
  '@media(max-width: 800px)': {
    width: 210,
  },
})

const uploadItemTextStyle = css({
  width: 246,
  height: 69,
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  paddingBottom: 14,
  p: {
    height: 20,
    fontSize: 14,
  },
  img: {
    width: 21,
    height: 18,
  },
  '&.uploaded': {
    color: '#fff',
  },
  '@media(max-width: 880px)': {
    width: 224,
  },
})

const selectBoxStyle = css({
  '-webkit-appearance': 'none',
  fontSize: 22,
  border: '1px solid #CCCCCC',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 20,
  display: 'block',
  paddingLeft: 40,
  paddingRight: 5,
  marginRight: 20,
  backgroundColor: '#fff',
  color: '#272727',
})

const largeSelectBoxStyle = css(selectBoxStyle, {
  width: '25%',
  height: 60,
  borderRadius: 40,
  backgroundPosition: '86% center',
})

const containerStyle = css({
  width: '100%',
  minHeight: '100vh',
  backgroundColor: '#F2F2F2',
  '*': {
    fontFamily: 'Noto Sans JP',
  },
})

const settingMainContainerStyle = css({
  height: 'calc(100% - 180px)',
  margin: '0 auto',
  maxWidth: 874,
})

const settingListContainerStyle = css({
  borderRadius: '0 5px 5px 5px',
  background: '#FFF',
  paddingBottom: 50,
  boxShadow: '0px 0px 6px #0000001a',
})

const settingHeaderContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const mainStyle = css({
  padding: '120px 0',
  '@media(max-width: 1080px)': {
    padding: '96px 0',
  },
})

const companionPassportImageWrapperStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'top',
  justifyContent: 'start',
})

const leftCompanionPassportImageWrapperStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'top',
  justifyContent: 'start',
  '&::after': {
    content: '"" !important',
    display: 'block',
    width: 282,
    height: 197,
    left: 0,
    top: 0,
  },
  '@media(max-width: 1080px)': {
    '&::after': {
      content: '"" !important',
      display: 'block',
      width: 246,
      height: 172,
    },
  },
})

const numberOfPeopleWrapperStyle = css({
  display: 'flex',
  marginBottom: 40,
  alignItems: 'center',
})

const numberOfPeopleStyle = css({
  fontSize: 22,
  fontWeight: 'bold',
  color: '#272727',
})
