import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { fetchDeviceSetting } from '@/apis/aipass'
import { deviceSettingsType, SendType } from '@/models/device/device-setting'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { PrinterTab } from '@/components/molecules/settings/printer-tab'
import { RadioButton } from '@/components/atoms/radio-button'
import { checkinEposPrinterIdStorage } from '@/components/organisms/self-checkin/complete/checkin-receipt'
import { useEposPrinter } from '@/hooks/use-epos-printer'
import { AccountContext } from '@/contexts/account'
import { notification } from 'antd'
import { errorHandler } from '@/libs/errors'

export const SettingPrinterSelfCheckin: React.FC = () => {
  type PrinterType = Pick<deviceSettingsType, 'id' | 'deviceName'>
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [printerSettings, setPrinterSettings] = useState<PrinterType[]>([])
  const [printerSettingId, setPrinterSettingId] = useState<string>('')
  const { account } = useContext<any>(AccountContext)
  const eposPrinter = useEposPrinter()

  const fetchPrinterSetting = async () => {
    setIsLoading(true)
    const deviceSettings = (await fetchDeviceSetting()) || []
    setPrinterSettings([
      { id: '', deviceName: t('Dont choose') },
      ...deviceSettings.filter(device => device.sendType === SendType.selfCheckin),
    ])
    setIsLoading(false)
  }

  const selectPrinter = async (device: PrinterType) => {
    setPrinterSettingId(device.id)
    localStorage.setItem(checkinEposPrinterIdStorage, device.id)
    if (!device.id) {
      return
    }

    let printerConnection
    try {
      printerConnection = await eposPrinter.initialize({
        hotelId: account.employee.hotelId,
        connectIdKey: checkinEposPrinterIdStorage,
      })
      notification.open({ message: 'プリンターの接続に成功しました' })
    } catch (e) {
      errorHandler(e)
    } finally {
      eposPrinter.disconnect(printerConnection?.connection, printerConnection?.printer)
    }
  }

  useEffect(() => {
    setPrinterSettingId(localStorage.getItem(checkinEposPrinterIdStorage) || '')
    fetchPrinterSetting()
  }, [])

  return (
    <SettingsLayout loading={isLoading}>
      <TabContainer tabComponent={<PrinterTab currentTab={'Self check-in'} />}>
        <div css={headerContainerStyle}>
          {t('Please make selections on the tablet (iPad) to be used to connect the printer.')}
          <br />
          {t('When using multiple tablets/printers, please set up each tablet individually.')}
        </div>
        <div css={infoHeaderContainerStyle}>
          <div>{t('Printer name')}</div>
        </div>
        <div css={mainContainerStyle}>
          {printerSettings.map(device => {
            return (
              <RadioButton
                key={device.id || 'empty'}
                id={device.id || 'empty'}
                checked={printerSettingId === device.id}
                item={{
                  value: device.id,
                  label: device.deviceName,
                }}
                onChange={() => selectPrinter(device)}
                style={{ radioSize: 20, labelMargin: 39 }}
                itemCss={css({
                  height: 64,
                  borderBottom: '1px solid #F2F2F2',
                  padding: '0 32px',
                  '> label': {
                    fontSize: 14,
                    fontWeight: 'bold',
                    letterSpacing: '1.4px',
                  },
                })}
              />
            )
          })}
        </div>
      </TabContainer>
    </SettingsLayout>
  )
}

const headerContainerStyle = css({
  height: 70,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  lineHeight: '17px',
  color: '#272727',
  letterSpacing: 0.56,
})

const mainContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  color: '#272727',
  width: '100%',
  flexWrap: 'wrap',
})

const infoHeaderContainerStyle = css({
  background: '#F5F5F5',
  height: 32,
  padding: '0 78px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 12,
  color: '#676767',
})
