import { terminalPortStorageKey, terminalStorageKey } from '@/components/pages/setting/checkin/terminal'
import { onlyForLocal } from '@/utils/check-environment'
import * as api from './aipass'

export const DeviceType = {
  PaidCreditCard: 'PCC',
  PaidQr: 'PQR',
} as const
export type DeviceType = (typeof DeviceType)[keyof typeof DeviceType]

export type OutputCompleteEvent = {
  CurrentService: string
  SequenceNumber: number
  SettledAmount: number
  AdditionalSecurityInformation: {
    brand: string
  }
}
export type ErrorEvent = {
  ErrorCode: number
  ErrorCodeExtended: number
  SequenceNumber: number
  CurrentService: string
  Errorcodedetail: string
  Message?: string
}

type AuthorizeSalesCommand = {
  AuthorizeSales: {
    SequenceNumber: number
    CurrentService: 'UnionPay' | 'Credit'
    Amount: number
    TaxOthers: number
    TrainingMode: boolean
    AdditionalSecurityInformation: {
      lang: 'ja' | 'en' | 'zh' | 'ko'
    }
  }
}
type SubtractValueCommand = {
  SubtractValue: {
    SequenceNumber: number
    CurrentService: 'QRPayment'
    Amount: number
    TrainingMode: boolean
    AdditionalSecurityInformation: {
      qrCodeMode: 'CPM'
      qrCode: string
    }
  }
}

/**
 * @see https://docs.google.com/spreadsheets/d/1BqAVP-ZZo1RbIZF0PG3fpwI--YjbrUL-
 */
export class VescaJs {
  public inProgress: boolean = false

  private hotelId: string
  private reservationIds: string[]
  private FullFeaturedWorker: any
  private config: { host: string; port: number }

  constructor(
    hotelId: string,
    reservationIds: string[],
    handler: {
      onSuccess: (data: OutputCompleteEvent) => void
      onCancel: (data: ErrorEvent) => void
      onError: (data: ErrorEvent) => void
    },
    config?: { host: string; port: number },
  ) {
    this.hotelId = hotelId
    this.reservationIds = reservationIds
    this.config = config || {
      host: localStorage.getItem(terminalStorageKey) || '',
      port: Number(localStorage.getItem(terminalPortStorageKey)) || 3647,
    }
    this.FullFeaturedWorker = this.createWorker(handler)
  }

  private makeTerminalHost(): string {
    const schema = window.location.protocol === 'https:' ? 'wss' : 'ws'
    return `${schema}://${this.config.host}:${this.config.port}`
  }

  private storeVescaResponse(command: 'OutputCompleteEvent' | 'ErrorEvent' | string, event: OutputCompleteEvent | ErrorEvent | any): void {
    api.storeVescaHistory({
      hotelId: this.hotelId,
      host: this.makeTerminalHost(),
      sequenceNumber: event.SequenceNumber,
      direction: 'response',
      command,
      amount: event.SettledAmount,
      message: event,
      checkinId: undefined,
      reservationIds: this.reservationIds,
    })
  }

  private storeVescaRequest(
    command: 'SubtractValue' | 'AuthorizeSales' | 'Cancel' | 'StartService',
    event: AuthorizeSalesCommand | SubtractValueCommand | any,
  ): void {
    api.storeVescaHistory({
      hotelId: this.hotelId,
      host: this.makeTerminalHost(),
      sequenceNumber: event[command].SequenceNumber,
      direction: 'request',
      command,
      amount: event[command].Amount,
      message: event,
      checkinId: undefined,
      reservationIds: this.reservationIds,
    })
  }

  private createWorker(handler) {
    const url = '/vescajs-fullfeaturedws.min.js'
    const worker = new Worker(url)
    worker.onmessage = e => {
      if (e.data !== 'undefined') {
        if (e.data.StatusEvent != null) {
          return
        }
      }

      const isErrorEvent = Object.keys(e.data).includes('ErrorEvent')
      const isCompleteEvent = Object.keys(e.data).includes('OutputCompleteEvent')

      if (isErrorEvent) {
        this.inProgress = false
        const event = e.data.ErrorEvent as ErrorEvent
        this.storeVescaResponse('ErrorEvent', event)
        const isCancelFromPos = event.ErrorCode === 114 && event.ErrorCodeExtended === -1
        const isCancelFromTerminal = event.ErrorCode === 114 && event.ErrorCodeExtended === -2
        if (isCancelFromTerminal) {
          handler.onCancel(event)
        } else if (isCancelFromPos) {
          // POS（=PMS）キャンセルはdoCancel呼び出し側でコントロールするため通知なし
        } else {
          handler.onError(event)
        }
      } else if (isCompleteEvent) {
        this.inProgress = false
        const event = e.data.OutputCompleteEvent as OutputCompleteEvent
        this.storeVescaResponse('OutputCompleteEvent', event)
        handler.onSuccess(event)
      } else {
        this.storeVescaResponse('Unknown', e.data)
      }
    }
    return worker
  }

  /**
   * @param {Object} request
   */
  private doPayRequest(request: AuthorizeSalesCommand | SubtractValueCommand) {
    const command = Object.keys(request).includes('SubtractValue') ? 'SubtractValue' : 'AuthorizeSales'
    this.storeVescaRequest(command, request)
    this.inProgress = true
    this.FullFeaturedWorker.postMessage({
      host: this.makeTerminalHost(),
      request,
    })
  }

  private makeSequenceNumber() {
    return Math.max(1, Math.floor(Math.random() * 2147483647))
  }

  /**
   * @see 5-1.QR決済（REQUEST）
   * @param params
   */
  doQrPay(params: { amount: number; tax: number; qrCode: string }) {
    this.doPayRequest({
      SubtractValue: {
        SequenceNumber: this.makeSequenceNumber(),
        CurrentService: 'QRPayment',
        Amount: params.amount,
        TrainingMode: onlyForLocal,
        AdditionalSecurityInformation: {
          qrCodeMode: 'CPM',
          qrCode: params.qrCode,
        },
      },
    })
  }

  /**
   * @see 2-1.クレジット・銀聯（REQUEST）
   * @param params
   */
  doCreditPay(params: { amount: number; tax: number; lang?: 'ja' | 'en' | 'zh' | 'ko'; unionPay?: boolean }) {
    this.doPayRequest({
      AuthorizeSales: {
        SequenceNumber: this.makeSequenceNumber(),
        CurrentService: params.unionPay ? 'UnionPay' : 'Credit',
        Amount: params.amount,
        TaxOthers: params.unionPay ? 0 : params.tax,
        TrainingMode: onlyForLocal,
        AdditionalSecurityInformation: { lang: params.lang || 'en' },
      },
    })
  }

  doConnect() {
    const request = { StartService: '' }
    this.storeVescaRequest('StartService', request)
    this.FullFeaturedWorker.postMessage({ host: this.makeTerminalHost(), request })
  }

  doCancel() {
    const request = { Cancel: true }
    this.storeVescaRequest('Cancel', request)
    this.FullFeaturedWorker.postMessage({ request })
  }
}
