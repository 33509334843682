import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import parse from 'html-react-parser'
import { SelfCheckinHeader } from '@/components/molecules'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { CustomCheckin } from '@/components/organisms/self-checkin/custom-checkin'
import { checkValidateGuidesRequired, addValidateGuidesRequired, changeValidateGuidesRequired, Guide } from '@/utils/guide'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { NationalityType } from '@/models/self-checkin/accommodation-info'
import { fetchTimeSelectionSpace } from '@/apis/aipass'
import { SelectableTimeSelectionType, SelectedTime, SpaceAssign } from '@/models/self-checkin/custom-checkin'
import { LoaderContextCreator } from '@/contexts/loader'
import { TimeSelectionForm } from '@/components/organisms/self-checkin/time-selection-form'
import { SelfCheckinLayout } from '@/components/layouts/self-checkin-layout'

export const CheckinCustomize: React.FC<{}> = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [guides, setGuides] = useState<Guide[]>([])
  const [validGuides, setValidGuides] = useState<any>([])
  const [timeSelections, setTimeSelections] = useState<SelectableTimeSelectionType[]>([])
  const [options, setOptions] = useState<any>()
  const [selectedInputValue, setSelectedInputValue] = useState<any>([])
  const [isInitializeComponent, setIsInitializeComponent] = useState(false)
  const [isRequiredTimeSelection, setIsRequiredTimeSelection] = useState(false)
  const [selectedTimes, setSelectedTimes] = useState<SelectedTime>({})
  const [assignSpaces, setAssignSpaces] = useState<SpaceAssign[]>([])
  const { isLoading, setIsLoading } = useContext(LoaderContextCreator())
  const history = useHistory()
  const { state, saveState } = useSelfCheckInState()

  const getTitle = guide => {
    if (lang === 'ja') {
      return parse(guide.jpTitle)
    }
    if (lang === 'en') {
      return parse(guide.enTitle)
    }
    if (lang === 'ko') {
      return parse(guide.koTitle)
    }
    if (lang === 'zh') {
      return parse(guide.zhTitle)
    }
  }

  const getContent = guide => {
    if (lang === 'ja') {
      return parse(guide.jpContent)
    }
    if (lang === 'en') {
      return parse(guide.enContent)
    }
    if (lang === 'ko') {
      return parse(guide.koContent)
    }
    if (lang === 'zh') {
      return parse(guide.zhContent)
    }
  }

  const getOption = guide => {
    if (lang === 'ja') {
      return guide.jpInputValue
    }
    if (lang === 'en') {
      return guide.enInputValue
    }
    if (lang === 'ko') {
      return guide.koInputValue
    }
    if (lang === 'zh') {
      return guide.zhInputValue
    }
  }

  const filterUniqueValueById = selectedInputValue => {
    const customCheckinIds = selectedInputValue.map(v => {
      return v.customCheckinId
    })
    return selectedInputValue.filter((v, index) => {
      // keep the last choice
      return customCheckinIds.lastIndexOf(v.customCheckinId) === index
    })
  }

  const onChangeState = (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>, customCheckinId) => {
    if (!e.target.value) {
      const addValidateGuides = addValidateGuidesRequired(customCheckinId, guides)
      if (addValidateGuides.length > 0) {
        const errorMessage = document.getElementById(addValidateGuides[0]['customCheckinId'] + '-error')
        if (errorMessage) {
          errorMessage.style.visibility = errorMessage ? 'visible' : ''
        }
      }
      setValidGuides(addValidateGuides.concat(validGuides))
    } else {
      const changeValidateGuides = changeValidateGuidesRequired(customCheckinId, validGuides)
      const errorMessage = document.getElementById(customCheckinId + '-error')
      if (errorMessage) {
        errorMessage.style.visibility = errorMessage.style.visibility === 'visible' ? 'hidden' : ''
      }
      setValidGuides(changeValidateGuides)
    }
    const value = e.target.value
    const name = e.target.name
    const newSlectedInputValue = [...selectedInputValue, { customCheckinId: customCheckinId, [name]: value }]
    setSelectedInputValue(filterUniqueValueById(newSlectedInputValue))
  }

  // Replace form content with input content, not filled in with -
  const replaceInputValue = () => {
    const _guides = [...guides]
    _guides.map(guide => {
      selectedInputValue.map(v => {
        if (v.customCheckinId === guide.customCheckinId) {
          // When you reselect Please select
          if (v.value === '') {
            guide.selectedAnswer = '-'
          } else {
            guide.selectedAnswer = v.value
          }
        }
      })
      // when not choosing
      if (!guide.selectedAnswer) {
        guide.selectedAnswer = '-'
      }
    })
    setGuides(_guides || [])
  }

  const fetchTimeSelection = async () => {
    if (!state.selectReservation || state.selectReservation?.selectedReservationIds.length === 0) {
      return
    }

    setIsLoading(true)
    const res = await fetchTimeSelectionSpace(state.hotelId, state.selectReservation.selectedReservationIds)
    setTimeSelections(res || [])
    setIsLoading(false)
    return res || []
  }

  const isDisabled = (): boolean => {
    const isRequiredGuides = validGuides.length > 0
    return isRequiredGuides || isRequiredTimeSelection || isLoading
  }

  const nextPath = () => {
    const isForeign = state.accommodationInfo?.nationality !== NationalityType.Jp
    return `/self-checkin/identity-verify/${isForeign ? 'upload-passport' : 'upload-license'}`
  }

  const onSubmit = () => {
    replaceInputValue()
    const nav = t('Information from the facility')
    const notes = guides.map(guide => {
      if (guide.inputType !== 'none') {
        return `${guide.jpTitle}:${guide.selectedAnswer}`
      }
    })
    const filteredNotes = notes.filter(v => v)
    let checkinMemo: string = ''
    if (filteredNotes.length !== 0) {
      checkinMemo = [nav, ...filteredNotes].join('\n')
    } else {
      checkinMemo = ''
    }

    saveState({
      ...state,
      checkinCustomize: {
        guides,
        checkinMemo,
        assignSpaces,
        selectedTime: selectedTimes,
        timeSelections,
      },
    })
    history.push({ pathname: nextPath() })
  }

  const initializeComponent = async () => {
    window.scrollTo(0, 0)
    if (!state?.hotelId) {
      history.replace({ pathname: '/dashboard' })
      return
    }

    // 在庫確認のために常に取得する
    const timeSelections = await fetchTimeSelection()

    if (state.selectReservation?.hasSmartCheckin || (!state.setting.customCheckin.length && !timeSelections.length)) {
      history.replace({ pathname: nextPath() })
      return
    }

    if (state.checkinCustomize) {
      setGuides(state.checkinCustomize.guides)
    } else {
      const isRequiredGuides = checkValidateGuidesRequired(state.setting.customCheckin)
      setValidGuides(isRequiredGuides)
      setGuides(state.setting.customCheckin)
    }
    setIsInitializeComponent(true)
  }

  useEffect(() => {
    initializeComponent()
  }, [])

  useEffect(() => {
    if (guides) {
      setOptions({})
      guides.map((guide, index) => {
        const translatedOption = getOption(guide)
        setOptions(prevOptions => ({
          ...prevOptions,
          [index]: translatedOption,
        }))
      })
    }
  }, [guides, lang])

  if (!isInitializeComponent) {
    return <></>
  }
  return (
    <SelfCheckinLayout>
      <div css={containerStyle}>
        <SelfCheckinHeader goToPreviousPage={history.goBack} title={t('Guidance')} />
        <div css={mainStyle}>
          <div css={containerWrapperStyle}>
            {guides?.map((guide, index) => (
              <CustomCheckin
                key={index}
                index={index}
                guide={guide}
                options={options}
                selectedInputValue={selectedInputValue}
                onChangeState={onChangeState}
                getTitle={getTitle}
                getContent={getContent}
              />
            ))}
          </div>

          <TimeSelectionForm
            timeSelections={timeSelections}
            setIsRequiredTimeSelection={setIsRequiredTimeSelection}
            setSelectedTimes={setSelectedTimes}
            setAssignSpaces={setAssignSpaces}
          />

          <SelfCheckinFooter isDisabled={isDisabled} goToNextPage={onSubmit} isNext={'next'} />
        </div>
      </div>
    </SelfCheckinLayout>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  fontFamily: 'Noto Sans JP',
  backgroundColor: '#F2F2F2',
  minHeight: '100vh',
})

const mainStyle = css({
  padding: '112px 0',
})

const containerWrapperStyle = css({
  margin: 'auto',
})
