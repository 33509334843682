import React from 'react'
import { css, CSSObject, SerializedStyles } from '@emotion/core'
import { RadioButton } from '../atoms/radio-button'
import uuid from 'react-uuid'

type RadioGroupProps<T> = {
  value?: T
  name?: string
  items: {
    id?: string
    value: T
    label: string
    disabled?: boolean
  }[]
  onChange: (e: React.ChangeEvent<HTMLInputElement & { value: T }>) => void
  groupCss?: CSSObject | SerializedStyles
  itemWrapperCss?: CSSObject | SerializedStyles
  itemCss?: CSSObject | SerializedStyles
  style?: {
    direction?: 'column' | 'row' | 'wrap'
    radioSize?: number
    labelMargin?: number
  }
  disabled?: boolean
  error?: string
  customErrorStyle?: CSSObject | SerializedStyles
}

export const RadioGroupField = <T,>({
  value,
  groupCss,
  itemWrapperCss,
  items,
  itemCss,
  name,
  style,
  onChange,
  disabled = false,
  error,
  customErrorStyle,
}: RadioGroupProps<T>) => {
  return (
    <>
      <div css={[groupDefaultStyle, groupCss]} style={{ flexFlow: style?.direction || 'row' }}>
        {items.map((item, index) => (
          <div key={index} css={[itemWrapperCss]}>
            <RadioButton<T>
              id={item?.id || uuid()}
              name={name}
              checked={item.value === value}
              item={item}
              onChange={onChange}
              style={style}
              itemCss={itemCss}
              disabled={disabled || item.disabled || false}
            />
          </div>
        ))}
      </div>
      {!!error && (
        <div css={[{ fontSize: 10, color: 'rgba(255,0,0,0.5)', marginTop: 4, position: 'absolute' }, customErrorStyle]}>{error}</div>
      )}
    </>
  )
}

const groupDefaultStyle = css({
  display: 'flex',
  overflow: 'auto',
  alignSelf: 'center',
})
