import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SelfCheckinHeader } from '@/components/molecules/self-checkin/header'
import { terminalStorageKey } from '../../setting/checkin/terminal'
import { ManualPaymentForm } from '@/components/organisms/self-checkin/payment/manual-payment-form'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { TerminalPaymentForm } from '@/components/organisms/self-checkin/payment/terminal-payment-form'
import { SelfCheckinLayout } from '@/components/layouts/self-checkin-layout'

export const CheckinPayment: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { state } = useSelfCheckInState()

  const isEnabledTerminal = useMemo((): boolean => {
    const storedTerminalIp = localStorage.getItem(terminalStorageKey) || ''
    return storedTerminalIp.length > 0
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!state?.hotelId) {
    history.replace({ pathname: '/dashboard' })
    return <></>
  }

  return (
    <SelfCheckinLayout>
      <SelfCheckinHeader goToPreviousPage={history.goBack} title={t('Payment details')} />
      {isEnabledTerminal && <TerminalPaymentForm />}
      {!isEnabledTerminal && <ManualPaymentForm />}
    </SelfCheckinLayout>
  )
}
