import React, { useState, useEffect, useContext } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { CheckinTabContainer } from '@/components/organisms/settings/checkin-tab-container'
import { InputField } from '@/components/molecules/input-field'
import { Controller, useForm } from 'react-hook-form'
import { EditFooter } from '@/components/organisms/edit-footer'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import * as vescajs from '@/apis/vescajs'
import { AccountContext } from '@/contexts/account'
import { LoaderContextCreator } from '@/contexts/loader'

type FormValue = {
  terminalIp: string
  terminalPort: string
}

export const terminalStorageKey = 'selfCheckin.terminal.ip'
export const terminalPortStorageKey = 'selfCheckin.terminal.port'

export const SelfCheckinTerminal: React.FC = () => {
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const { setIsLoading } = useContext(LoaderContextCreator())
  const [isShowFooter, setIsShowFooter] = useState<boolean>(false)
  const { control, handleSubmit, reset } = useForm<FormValue>({
    defaultValues: { terminalIp: '', terminalPort: '3647' },
  })

  const onSave = async (value: FormValue) => {
    if (!value.terminalIp) {
      setIsShowFooter(false)
      localStorage.setItem(terminalStorageKey, value.terminalIp)
      localStorage.setItem(terminalPortStorageKey, value.terminalPort)
      return
    }

    const vescaJs = new vescajs.VescaJs(
      account.hotel.id,
      [],
      {
        onCancel: () => {
          setIsLoading(false)
          alert(t('Connection has been canceled.'))
        },
        onError: (data: vescajs.ErrorEvent) => {
          setIsLoading(false)
          alert(`${t('Could not connect.')}\n[${data.ErrorCode}] ${data.Message}`)
        },
        onSuccess: () => {
          setIsLoading(false)
          setIsShowFooter(false)
          localStorage.setItem(terminalStorageKey, value.terminalIp)
          localStorage.setItem(terminalPortStorageKey, value.terminalPort)
        },
      },
      {
        host: value.terminalIp,
        port: Number(value.terminalPort),
      },
    )
    setIsLoading(true)
    vescaJs.doConnect()
  }

  const restoreSetting = () => {
    const storedIp = localStorage.getItem(terminalStorageKey)
    const storedPort = localStorage.getItem(terminalPortStorageKey)
    reset({
      terminalIp: storedIp || '',
      terminalPort: storedPort || '3647',
    })
  }

  useEffect(() => {
    restoreSetting()
  }, [])

  const footerContent = isShowFooter ? (
    <EditFooter
      onSave={handleSubmit(onSave)}
      onCancel={() => {
        restoreSetting()
        setIsShowFooter(false)
      }}
    />
  ) : undefined

  return (
    <>
      <SettingsLayout loading={false} footerContent={footerContent}>
        <TabContainer tabComponent={<CheckinTabContainer currentTab="Terminal" />} isShowFooter={isShowFooter}>
          <div css={containerStyle}>
            <div style={{ width: 300 }}>
              <div className="input-label">{t('IP Address of terminal')}</div>
              <Controller
                control={control}
                name="terminalIp"
                render={({ field: { onChange, value } }) => (
                  <InputField
                    value={value}
                    handleChangeData={e => {
                      setIsShowFooter(true)
                      onChange(e)
                    }}
                    placeholder="127.0.0.1"
                  />
                )}
              />
            </div>
            <div style={{ width: 300 }}>
              <div className="input-label">{t('Port of terminal')}</div>
              <Controller
                control={control}
                name="terminalPort"
                render={({ field: { onChange, value } }) => (
                  <InputField
                    value={value}
                    handleChangeData={e => {
                      setIsShowFooter(true)
                      onChange(e)
                    }}
                    placeholder="3647"
                  />
                )}
              />
            </div>
          </div>
        </TabContainer>
      </SettingsLayout>
    </>
  )
}

const containerStyle = css({
  padding: 32,
  '.input-label': {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 12,
  },
})
