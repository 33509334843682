import { fetchDeviceSetting } from '@/apis/aipass'
import { deviceSettingsType } from '@/models/device/device-setting'
import { useErrorHandler } from './use-error-handler'
import { useFileUpload } from './use-file-upload'

type ConfigType = {
  hotelId: string
  connectIdKey: string
  isStayConnected?: boolean
}
export interface EposPrinter {
  initialize: (config: ConfigType) => Promise<{ connection: any; printer: any }>
  printImage: (canvas: HTMLCanvasElement, checkinId: string, hotelId: string, printer?) => Promise<void>
  disconnect: (connection: any, printer: any) => void
}

export const useEposPrinter = () => {
  const { errorHandler } = useErrorHandler()
  const { upload: fileUpload } = useFileUpload()

  const connect = async (ip: string, port: number) => {
    return await new Promise<any>((resolve, reject) => {
      const ePosDev = new (window as any).epson.ePOSDevice()
      ePosDev.connect(
        ip,
        port,
        connectStatus => {
          if (connectStatus !== 'OK' && connectStatus !== 'SSL_CONNECT_OK') {
            reject(`プリンターに接続できません. status:${connectStatus}`)
            return
          }
          resolve(ePosDev)
        },
        { eposprint: true },
      )
      ePosDev.onreconnecting = () => console.log('onreconnecting')
      ePosDev.onreconnect = () => console.log('onreconnect')
      ePosDev.ondisconnect = () => console.log('ondisconnect')
    })
  }

  const disconnect = (connection, printer): void => {
    if (!connection?.isConnected) {
      return
    }
    if (!printer) {
      connection.disconnect()
      return
    }
    connection.deleteDevice(printer, () => connection.disconnect())
  }

  const createPrinter = async (connection, isStayConnected: boolean) => {
    return new Promise<any>((resolve, reject) => {
      connection!.createDevice(
        'local_printer',
        connection.DEVICE_TYPE_PRINTER,
        { crypto: false, buffer: false },
        (deviceObject, errorCode) => {
          if (!deviceObject || errorCode !== 'OK') {
            reject(`プリンターに接続できません. code:${errorCode}`)
            return
          }
          deviceObject.timeout = 3000
          deviceObject.onreceive = res => {
            console.log({ res })
            if (!isStayConnected) {
              disconnect(connection, deviceObject)
            }
          }
          resolve(deviceObject)
        },
      )
    })
  }

  const getPrinterToConnectTo = async (hotelId: string, connectIdKey: string): Promise<deviceSettingsType | undefined> => {
    const allDevice = await fetchDeviceSetting(hotelId)
    if (!allDevice) {
      return
    }
    return allDevice.find(device => device.id === localStorage.getItem(connectIdKey))
  }

  const initialize = async (config: ConfigType): Promise<{ connection: any; printer: any }> => {
    const printerSetting = await getPrinterToConnectTo(config.hotelId, config.connectIdKey)
    if (!printerSetting) {
      throw new Error('Invalid device setting.')
    }
    const connection = await connect(printerSetting.ipAddress, printerSetting.port)
    const printer = await createPrinter(connection, !!config.isStayConnected)

    return { connection, printer }
  }

  const printImage = async (canvas: HTMLCanvasElement, checkinId: string, hotelId: string, printer): Promise<void> => {
    try {
      printer.addImage(canvas.getContext('2d'), 0, 0, canvas.width, canvas.height)
      printer.addCut(printer.CUT_FEED)
      printer.send()
    } catch (error) {
      console.error(error)
      errorHandler(error)
    }
    try {
      await fileUpload({
        hotelId,
        file: {
          name: `${checkinId}_w${canvas.width}_h${canvas.height}_s${window.devicePixelRatio}.jpeg`,
          type: 'image/jpeg',
        },
        fileData: canvas.toDataURL('image/jpeg'),
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { printImage, initialize, disconnect }
}
