import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SelfCheckinHeader } from '@/components/molecules/self-checkin/header'
import { SuccessDialog } from '@/components/organisms/self-checkin/payment/success-dialog'
import { ErrorDialog } from '@/components/organisms/self-checkin/payment/error-dialog'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import * as vescajs from '@/apis/vescajs'
import { LanguageType } from '@/i18n'
import { useGuestAppUrl } from '@/hooks/use-guest-app-url'
import { useQuery } from '@/hooks/use-query'
import { SelfCheckinLayout } from '@/components/layouts/self-checkin-layout'

export type CardInfo = {
  cardNumber: string
  expiredMonth: string
  expiredYear: string
  holderName: string
  cvc: string
}

export const SelfCheckinPaymentTerminalWithCard: React.FC = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const query = useQuery()
  const { state, doCheckIn } = useSelfCheckInState()
  const [receiveTerminalStatus, setReceiveTerminalStatus] = useState<'progress' | 'success' | 'error'>()
  const { mypageUrl, homePageUrl } = useGuestAppUrl({
    hotelId: state.hotelId,
    plugin: state.setting.plugin,
  })

  const onSuccess = async (event: vescajs.OutputCompleteEvent) => {
    setReceiveTerminalStatus('success')
    try {
      await doCheckIn({
        state,
        language: i18n.language,
        guestAppUrl: { mypageUrl, homePageUrl },
        selectedPaymentMethod: 'CREDIT_CARD',
        paymentResult: {
          orderId: `${event.SequenceNumber}`,
          cardBrand: event.AdditionalSecurityInformation.brand,
          amount: event.SettledAmount,
          totalAmountAt10Per: 0,
          totalAmountAt8Per: 0,
          totalTaxAt10Per: 0,
          totalTaxAt8Per: 0,
          deviceType: vescajs.DeviceType.PaidCreditCard,
        },
      })
      history.replace({ pathname: '/self-checkin/issue-room-key' })
    } catch (e) {
      console.error(e)
      alert(t('Please complete the procedure at the front desk'))
      history.push({ pathname: `/self-checkin`, search: `?hotelId=${state.hotelId}` })
    }
  }

  useEffect(() => {
    const vescaJs = new vescajs.VescaJs(state.hotelId, state.selectReservation?.selectedReservationIds || [], {
      onCancel: () => {
        setReceiveTerminalStatus(undefined)
        history.goBack()
      },
      onError: () => {
        setReceiveTerminalStatus('error')
        setTimeout(() => history.goBack(), 5000)
      },
      onSuccess,
    })

    vescaJs.doCreditPay({
      amount: state.confirm!.payment!.usage.totalAmount,
      tax: 0,
      lang: i18n.language as LanguageType,
      unionPay: query.has('unionPay'),
    })
    setReceiveTerminalStatus('progress')

    return () => {
      if (vescaJs && vescaJs.inProgress) {
        vescaJs.doCancel()
      }
    }
  }, [])

  return (
    <SelfCheckinLayout>
      <SelfCheckinHeader goToPreviousPage={() => history.goBack()} title={t('Payment details')} />
      <div css={containerStyle}>
        <div style={{ maxWidth: 880 }}>
          <div className="operation-block">
            <div>{t('Insert or touch the card')}</div>
            <div>{t('To change the payment method, press the red button on the payment terminal.')}</div>
          </div>

          <div className="terminal-and-brand-block">
            <div className="terminal-image-block">
              <img src={require('@/static/images/self-checkin-payment/terminal/image_01.png')} />
              <img src={require('@/static/images/self-checkin-payment/terminal/image_02.png')} />
            </div>
            <div className="brand-image-block">
              <div className="-label">{t('Supported Credits')}</div>
              <div className="-separate"></div>
              <div className="-image">
                <img src={require('@/static/images/self-checkin-payment/terminal/logo_card02.png')} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {receiveTerminalStatus === 'success' && <SuccessDialog />}
      {receiveTerminalStatus === 'error' && <ErrorDialog />}
    </SelfCheckinLayout>
  )
}

const containerStyle = css({
  backgroundColor: '#f2f2f2',
  display: 'flex',
  justifyContent: 'center',
  // add header size (=80)
  paddingTop: 32 + 80,
  paddingBottom: 32,
  height: '100vh',
  width: '100%',
  '*': { fontFamily: 'Noto ans JP', color: '#272727' },
  '.operation-block': {
    textAlign: 'center',
    'div:first-of-type': {
      fontSize: 21,
      lineHeight: 1.5,
      fontWeight: 'bold',
      letterSpacing: 3.15,
    },
    'div:last-of-type': {
      paddingTop: 8,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 'bold',
      letterSpacing: 2.4,
      color: '#676767',
    },
  },
  '.terminal-and-brand-block': {
    marginTop: 32,
    backgroundColor: '#ffffff',
    borderRadius: 10,
    border: '1px solid #F2F2F2',
    padding: 40,
    '.terminal-image-block': {
      display: 'flex',
      img: {
        width: '50%',
        ':first-of-type': { marginRight: 12 },
        ':last-of-type': { marginLeft: 12 },
      },
    },
    '.brand-image-block': {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      border: '1px solid #CCCCCC',
      borderRadius: 10,
      marginTop: 24,
      padding: '20px 40px',
      '.-label': {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 2.1,
        color: '#676767',
        width: '20%',
        minWidth: '20%',
      },
      '.-separate': {
        margin: '0 40px',
        height: 43,
        borderRight: '1px solid #CCCCCC',
      },
      '.-image': {
        flexGrow: 1,
        img: { width: '100%', objectFit: 'contain', height: 43 },
      },
    },
  },
})
