import { useMemo } from 'react'
import { SelfCheckInState } from '@/models/self-checkin'
import { SelfCheckinBasicInfoPluginType } from '@/models/self-checkin/results'
import { Guide } from '@/utils/guide'
import { checkActivePlugin, PluginCode } from '@/libs/plugins'
import * as api from '@/apis/aipass'
import { useAssignKey } from './use-assign-key'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { isEmployee, OccupationType } from '@/models/self-checkin/accommodation-info'
import { ApprovedStatus } from '@/models/reservation-approved-status'
import { DeviceType } from '@/apis/vescajs'
import { groupBy } from 'lodash'
import { ReservationForSelfCheckinInput } from '@/models/space-manager'

export const useSelfCheckInState = () => {
  const { fetchRoomKey } = useAssignKey()

  const saveState = (state: SelfCheckInState) => {
    sessionStorage.setItem('selfcheckinstate', JSON.stringify(state))
  }

  const clearState = () => {
    sessionStorage.setItem('selfcheckinstate', JSON.stringify({}))
  }

  const fetchState = (): SelfCheckInState => {
    return JSON.parse(sessionStorage.getItem('selfcheckinstate') || '{}')
  }

  const initializeState = (
    hotelId: string,
    setting: {
      basicInfo: SelfCheckinBasicInfoPluginType
      customCheckin: Array<Guide>
      payment: Array<'CREDIT_CARD' | 'DIRECT'>
      paymentTerminal: { isEnabledCash: boolean; isEnabledQr: boolean } | undefined
      selfCheckinSetting:
        | {
            requiredAccompanyNameInput: number | boolean
            requiredAddressInput: number | boolean
            requiredBirthDateInput: number | boolean
            requiredEmailInput: number | boolean
            requiredForeignIdentify: number | boolean
            requiredFuriganaInput: number | boolean
            requiredGenderInput: number | boolean
            requiredIdentify: number | boolean
            requiredTelephoneInput: number | boolean
            isFrontDescGuidance: number | boolean
          }
        | undefined
    },
  ): SelfCheckInState => {
    const castSelfCheckinSetting = {} as any
    if (setting.selfCheckinSetting) {
      Object.keys(setting.selfCheckinSetting).forEach(k => (castSelfCheckinSetting![k] = !!setting.selfCheckinSetting![k]))
      if (castSelfCheckinSetting?.requiredIdentify !== undefined) {
        Object.assign(castSelfCheckinSetting, {
          requiredJapaneseIdentify: castSelfCheckinSetting.requiredIdentify,
        })
      }
    }
    const hasPaymentPlugin = checkActivePlugin(setting.basicInfo.plugin, PluginCode.Payment)
    return {
      hotelId,
      setting: {
        ...setting.basicInfo,
        basicInfo: setting.basicInfo.basicInfo[0],
        customCheckin: setting.customCheckin,
        accommodation: {
          requiredJapaneseIdentify: true,
          requiredForeignIdentify: true,
          requiredAccompanyNameInput: false,
          requiredFuriganaInput: false,
          requiredBirthDateInput: false,
          requiredGenderInput: false,
          requiredTelephoneInput: false,
          requiredEmailInput: false,
          requiredAddressInput: false,
          isFrontDescGuidance: false,
          ...castSelfCheckinSetting,
        },
        payment: hasPaymentPlugin ? setting.payment : [],
        paymentTerminal: setting.paymentTerminal,
      },
    }
  }

  const doCheckIn = async (params: {
    state: SelfCheckInState
    language: string
    guestAppUrl: any
    selectedPaymentMethod: 'CREDIT_CARD' | 'DIRECT'
    paymentResult?: {
      orderId: string
      cardBrand: string
      amount: number
      totalAmountAt10Per: number
      totalAmountAt8Per: number
      totalTaxAt10Per: number
      totalTaxAt8Per: number
      deviceType?: DeviceType
    }
  }) => {
    api.stateLog(params.state)
    const now = dayjs().unix()

    let allSelectionSpace: ReservationForSelfCheckinInput['assignSpaces'] = []
    if (state.checkinCustomize?.assignSpaces?.length) {
      const groupedAssignSpaces = groupBy(state.checkinCustomize?.assignSpaces, 'reservationId')
      try {
        const groupReservationMap = Object.keys(groupedAssignSpaces)
        for (let i = 0; i < groupReservationMap.length; i++) {
          allSelectionSpace.push()
          const assignSpaces = groupedAssignSpaces[groupReservationMap[i]].map(space => ({
            spaceId: space.spaceId,
            reservationUserName: space.reservationUserName,
            pax: space.pax,
            usageFrom: space.usageFrom,
            usageTo: space.usageTo,
            reservationId: groupReservationMap[i],
            timeSelectionId: space.timeSelectionId,
          }))
          allSelectionSpace = allSelectionSpace.concat(assignSpaces)
          await api.CreateSpaceReservationForGuest({
            reservationId: groupReservationMap[i],
            paymentMethodType: 'checkout',
            hotelId: state.hotelId,
            isSkipMail: true,
            assignSpaces,
          })
        }
      } catch (e) {
        console.error(e)
      }
    }

    let reservationIds = params.state.selectReservation?.selectedReservationIds || []
    if (!reservationIds.length) {
      const reservationData = {
        hotelId: state.hotelId,
        beginDate: now,
      }
      const { reservationId } = await api.selfCheckinCreateReservation(reservationData)
      reservationIds = [reservationId]
    }

    const isOtherOccupation = params.state.accommodationInfo?.occupation === OccupationType.Other
    const checkinData = {
      checkinId: state.selectReservation?.smartCheckinId ? state.selectReservation.smartCheckinId : uuidv4(),
      reservationIds,
      checkin: {
        hotelId: params.state.hotelId,
        approvedStatus: 0,
        accompany: params.state.accommodationInfo?.accompany.filter(accompany => accompany?.name.trim()) || [],
        guest: {
          name: `${params.state.accommodationInfo!.lastName} ${params.state.accommodationInfo!.firstName}`,
          nameKana:
            params.state.accommodationInfo!.nationality === 'japanese' && params.language === 'ja'
              ? `${params.state.accommodationInfo!.lastNameFurigana} ${params.state.accommodationInfo!.firstNameFurigana}`
              : '',
          birthDate: params.state.accommodationInfo!.dateOfBirth,
          nationality: params.state.accommodationInfo!.nationality == 'japanese' ? 'JPN' : 'NJP',
          gender: params.state.accommodationInfo!.gender,
          postalCode: params.state.accommodationInfo!.nationality == 'japanese' ? params.state.accommodationInfo!.postCode : '',
          address: params.state.accommodationInfo!.address,
          telephone: params.state.accommodationInfo!.phoneNumber,
          email: params.state.accommodationInfo!.mail,
          occupation: params.state.accommodationInfo!.occupation,
          otherOccupation: isOtherOccupation ? params.state.accommodationInfo!.otherOccupation : null,
          company: isEmployee(params.state.accommodationInfo?.occupation) ? params.state.accommodationInfo!.company : '',
          totalMember: null,
          picture: null,
          credential: null,
        },
        checkinTime: now,
        userCheckinTime: now,
        nextPlaceToStay: '自宅',
        previousPlaceToStay: '自宅',
        jpnGtdStatus: 0,
      },
    }
    const checkinResponseInfo = await api.selfCheckinForReservations(checkinData)

    if (params.state.uploadLicense?.identificationFrontImage) {
      await api.selfCheckinUploadIdentificationImages({
        hotelId: params.state.hotelId,
        identification: {
          checkinId: checkinData.checkinId,
          images: [
            { isDriversLicense: 1, imageBase64: params.state.uploadLicense.identificationFrontImage, name: '免許証表面' },
            { isDriversLicense: 1, imageBase64: params.state.uploadLicense.identificationBackImage, name: '免許証裏面' },
          ],
        },
      })
    } else if (params.state.uploadLicense?.representativePassportImage) {
      const identificationImageData = {
        hotelId: params.state.hotelId,
        identification: {
          checkinId: checkinData.checkinId,
          images: [{ isDriversLicense: 1, imageBase64: params.state.uploadLicense.representativePassportImage, name: '代表者パスポート' }],
        },
      }
      params.state.uploadLicense.companionImageArray?.forEach(companionImg =>
        identificationImageData.identification.images.push({
          isDriversLicense: 1,
          imageBase64: companionImg.companionPassportImage,
          name: '同行者パスポート',
        }),
      )
      await api.selfCheckinUploadIdentificationImages(identificationImageData)
    }

    if (!state.selectReservation?.smartCheckinId) {
      await api.selfCheckinLinkReservations({
        checkinId: checkinData.checkinId,
        hotelId: params.state.hotelId,
        reservationIds,
      })
    }

    const approvedData = {
      approvedStatus: ApprovedStatus.Stay,
      hotelId: params.state.hotelId,
      reservationIds,
      checkinId: checkinData.checkinId,
      assignSpaces: allSelectionSpace,
    }
    if (params.state.confirm?.isDeliveryConfirm !== undefined) {
      Object.assign(approvedData, { deliveryAgreementStatus: params.state.confirm.isDeliveryConfirm ? 'deliverable' : 'nonDeliverable' })
    }
    if (params.state.checkinCustomize) {
      Object.assign(approvedData, { checkinMemo: params.state.checkinCustomize?.checkinMemo || '' })
    }
    await api.selfCheckinApprovedReservations(approvedData)

    const roomInformations = await fetchRoomKey({
      hotelId: params.state.hotelId,
      checkinId: checkinData.checkinId,
      reservationIds,
    })

    const emailData = {
      checkinId: checkinData.checkinId,
      is_enabled_smk: roomInformations.length ? 1 : 0,
      is_cc_payment: 0,
      email: checkinData.checkin.guest.email,
      name: checkinData.checkin.guest.name,
      hotelName: params.state.setting.basicInfo.hotelName,
      checkinDateTime: dayjs().format('YYYY-MM-DD HH:mm'),
      checkinCode: checkinResponseInfo.checkinCode,
      mypageUrl: params.guestAppUrl.mypageUrl(checkinData.checkinId, checkinResponseInfo.checkinCode),
      facilityGuideUrl: params.guestAppUrl.homePageUrl,
      hotelPhoneNumber: params.state.setting.basicInfo.telephone,
      roomInformations,
      assignSpaces: allSelectionSpace,
      _cp: 'confirm',
    }
    const isPaidCreditCard = params.paymentResult && params.state.confirm?.payment
    if (isPaidCreditCard) {
      const reception = params.paymentResult
        ? {
            number: params.paymentResult.orderId,
            date: dayjs().format('YYYY-MM-DD HH:mm'),
            paymentMethod: params.paymentResult.cardBrand,
            paymentAmount: `${params.paymentResult.amount}`,
          }
        : {}
      const reservationSaleIds: string[] = []
      const reservationsPaymentDetail: Array<{
        reservationNumber: number
        useAmount: number
        paymentAmount: number
        internalDiscount: number
      }> = []
      params.state.confirm!.payment!.usage.reservations.forEach((reservation, index) => {
        reservationsPaymentDetail.push({
          reservationNumber: index + 1,
          useAmount: reservation.totalPrice,
          paymentAmount: reservation.totalDiscount,
          internalDiscount: reservation.totalDiscount,
        })
        reservationSaleIds.push(...reservation.sales.map(s => s.id))
        reservationSaleIds.push(...reservation.salesDiscounts.map(s => s.id))
      })
      Object.assign(emailData, {
        hotelId: params.state.hotelId,
        is_cc_payment: 1,
        reception,
        reservations: reservationsPaymentDetail,
        saleIds: reservationSaleIds,
      })
    }
    if (emailData.email) await api.sendMailSelfCheckin(emailData)

    saveState({
      ...params.state,
      complete: {
        hotelId: params.state.hotelId,
        checkinId: checkinData.checkinId,
        basicInfo: params.state.setting.basicInfo,
        isFrontDescGuidance: params.state.setting.accommodation.isFrontDescGuidance,
        roomInformation: roomInformations,
        paymentMethod: params.selectedPaymentMethod,
        payment: isPaidCreditCard
          ? {
              totalAmount: params.state.confirm!.payment!.usage.totalAmount || 0,
              receiptName: params.state.confirm!.payment!.receiptName,
              paymentAmount: params.paymentResult!.amount,
              totalAmountAt10Per: params.paymentResult!.totalAmountAt10Per,
              totalAmountAt8Per: params.paymentResult!.totalAmountAt8Per,
              totalTaxAt10Per: params.paymentResult!.totalTaxAt10Per,
              totalTaxAt8Per: params.paymentResult!.totalTaxAt8Per,
              deviceType: params.paymentResult!.deviceType,
              brandName: params.paymentResult!.cardBrand,
            }
          : undefined,
      },
    })
  }

  const state = useMemo(() => fetchState(), [])

  return { state, saveState, clearState, initializeState, doCheckIn }
}
