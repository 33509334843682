import React, { ComponentProps } from 'react'
import { CSSObject, SerializedStyles, css } from '@emotion/core'

// components
import { Label } from '@/components/molecules/label'

export type Props = {
  label?: string
  required?: boolean
  placeholder?: string
  fieldWidth?: string
  width?: string
  marginBottom?: number
  name?: string
  size?: number
  error?: string
  handleChangeData?: (e: any) => void
  value?: string | number
  maxLength?: number
  type?: string
  disabled?: boolean
  customInputStyle?: CSSObject | SerializedStyles
  customErrorStyle?: CSSObject | SerializedStyles
}

export const InputField: React.FC<ComponentProps<'input'> & Props> = ({
  label,
  required = false,
  placeholder,
  fieldWidth = '100%',
  width = '100%',
  marginBottom = 16,
  name,
  size,
  error,
  handleChangeData,
  value,
  maxLength,
  type,
  disabled = false,
  customInputStyle,
  customErrorStyle,
  ...prop
}) => {
  return (
    <div style={{ marginBottom: marginBottom, width: fieldWidth, position: 'relative' }}>
      {label && <Label label={label} required={required} error={error} />}
      <input
        name={name}
        size={size}
        value={value}
        css={[inputStyle, customInputStyle, error ? { border: '1px solid red' } : {}]}
        style={{ width: width }}
        placeholder={placeholder}
        maxLength={maxLength}
        type={type}
        onChange={e => handleChangeData && handleChangeData(e)}
        disabled={disabled}
        {...prop}
      />
      {!!error && <div css={[{ fontSize: 10, color: 'rgba(255,0,0,0.5)', marginTop: 4, position: 'absolute' }, customErrorStyle]}>{error}</div>}
    </div>
  )
}

const inputStyle = css({
  padding: '9px 16px',
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  fontSize: 14,
  lineHeight: '14px',
  height: 32,
  '::placeholder': {
    color: '#CCCCCC',
  },
})
