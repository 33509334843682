import { PrinterContextCreator } from '@/contexts/printer'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { checkinEposPrinterIdStorage } from '../organisms/self-checkin/complete/checkin-receipt'

export const SelfCheckinLayout: React.FC = props => {
  const { eposPrinter, setConnection, connection, setPrinter, printer } = useContext(PrinterContextCreator())
  const { state } = useSelfCheckInState()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const hotelIdFromQuery = params.get('hotelId')

  useEffect(() => {
    if (!localStorage.getItem(checkinEposPrinterIdStorage)) {
      return
    }
    if (!hotelIdFromQuery && !state.hotelId) {
      return
    }
    if (connection?.isConnected() && printer) {
      return
    }
    const hotelId = hotelIdFromQuery || state.hotelId
    eposPrinter
      .initialize({
        hotelId,
        connectIdKey: checkinEposPrinterIdStorage,
        isStayConnected: true,
      })
      .then(({ printer, connection }) => {
        setConnection(connection)
        setPrinter(printer)
      })
  }, [])

  return <>{props.children}</>
}
