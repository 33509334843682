import React, { useState, useEffect, useCallback } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { LanguageType } from '@/i18n'
import { convertToSeparatedName } from '@/libs/convertToSeparatedName'
import { SelfCheckinHeader } from '@/components/molecules/self-checkin/header'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { AccommodationInput, GenderType, NationalityType, OccupationType, isEmployee } from '@/models/self-checkin/accommodation-info'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { SelfCheckInState } from '@/models/self-checkin'
import { v4 as uuidv4 } from 'uuid'
import { AccommodationForm } from '@/components/organisms/self-checkin/accommodation-form'
import { SearchReservationTypeWhenCheckedIn } from '@/models/reservation'
import { SelfCheckinLayout } from '@/components/layouts/self-checkin-layout'

export const AccommodationInfo: React.FC<{}> = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [isFormValidRequiredFields, setIsFormValidRequiredFields] = useState(true)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [isPostCodeValid, setIsPostCodeValid] = useState(true)
  const [isLastNameValid, setIsLastNameValid] = useState(true)
  const [isFirstNameValid, setIsFirstNameValid] = useState(true)
  const [isInitializeComponent, setIsInitializeComponent] = useState(false)
  const { state, saveState } = useSelfCheckInState()
  const accommodationInfoInitialData: AccommodationInput = {
    nationality: lang === LanguageType.ja ? NationalityType.Jp : NationalityType.Njp,
    firstName: '',
    lastName: '',
    firstNameFurigana: '',
    lastNameFurigana: '',
    dateOfBirth: '',
    gender: GenderType.Male,
    phoneNumber: '',
    mail: '',
    occupation: OccupationType.Employee,
    otherOccupation: '',
    postCode: '',
    company: '',
    address: '',
    paxTotal: state.selectReservation?.paxTotal || 1,
    accompany: [],
    hasContactForAllGuest: !state.setting.accommodation.requiredAccompanyNameInput,
  }
  const [accommodationInfo, setAccommodationInfo] = useState<AccommodationInput>(accommodationInfoInitialData)
  const [listValidateProps, setListValidateProps] = useState(['firstName', 'lastName'])

  const checkWhiteSpace = (value: string) => {
    return value.includes(' ')
  }

  const parseAccommodationFromCheckin = (reservation: SearchReservationTypeWhenCheckedIn): AccommodationInput => {
    const enteredAccompanies = reservation.accompany?.length
      ? reservation.accompany.map(value => {
          const lastName = value.name.slice(0, value.name.indexOf(' '))
          const firstName = value.name.slice(value.name.indexOf(' ') + 1, value.name.length)
          return { id: value.id, name: value.name, lastName: lastName, firstName: firstName }
        })
      : []
    return {
      nationality: reservation.nationality === 'JPN' || !reservation.nationality ? 'japanese' : 'nonJapanese',
      firstName: checkWhiteSpace(reservation.name) ? reservation.name.substring(reservation.name.indexOf(' ') + 1) : '',
      lastName: checkWhiteSpace(reservation.name) ? reservation.name.substring(0, reservation.name.indexOf(' ')) : reservation.name,
      firstNameFurigana: checkWhiteSpace(reservation.nameKana) ? reservation.nameKana.substring(reservation.nameKana.indexOf(' ') + 1) : '',
      lastNameFurigana: checkWhiteSpace(reservation.nameKana)
        ? reservation.nameKana.substring(0, reservation.nameKana.indexOf(' '))
        : reservation.nameKana,
      dateOfBirth: reservation.birthDate ?? '',
      gender: reservation.gender ?? 'M',
      phoneNumber: reservation.telephone && reservation.telephone !== '0' ? reservation.telephone : '',
      mail: reservation.email ?? '',
      occupation: reservation.occupation ?? '',
      otherOccupation: reservation.otherOccupation ?? '',
      postCode: '',
      company: reservation.company ?? '',
      address: reservation.address ?? '',
      accompany: enteredAccompanies,
      paxTotal: state.selectReservation?.paxTotal || 1,
      hasContactForAllGuest: !state.setting.accommodation.requiredAccompanyNameInput,
    }
  }

  const isDisabled = (): boolean => {
    const isValid = checkValidateFormRequired()
    return !isValid
  }

  const checkValidateFormRequired = () => {
    for (const property in accommodationInfo) {
      if (listValidateProps.includes(property)) {
        if (!accommodationInfo[property] || (typeof accommodationInfo[property] === 'string' && !accommodationInfo[property].trim())) {
          return false
        }
      }
    }
    if (lang === 'ja' && accommodationInfo.nationality === NationalityType.Jp && state.setting.accommodation.requiredFuriganaInput) {
      if (!accommodationInfo.firstNameFurigana.trim() || !accommodationInfo.lastNameFurigana.trim()) {
        return false
      }
    }
    const validAccompany = accommodationInfo.accompany?.filter(v => !v.lastName.trim() || !v.firstName.trim()) ?? []
    if (!accommodationInfo.hasContactForAllGuest && validAccompany.length > 0) {
      return false
    }

    return true
  }

  const checkEmailValid = value => {
    const halfByteRegex = /^[!-~]+$/
    const mailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return halfByteRegex.test(value) && mailRegex.test(value)
  }

  const checkNumberOnly = value => {
    return /^[0-9]*$/.test(value)
  }

  const checkOnlyAnphabet = value => {
    return /^[a-zA-Z\s]*$/.test(value)
  }

  const onChangeAccommodationInfo = (name, value) => {
    if (listValidateProps.includes(name)) {
      if (typeof value === 'string' && !value.trim()) {
        setIsFormValidRequiredFields(false)
      }
    }
    if (
      (name === 'firstNameFurigana' || name === 'lastNameFurigana') &&
      lang === 'ja' &&
      accommodationInfo?.nationality === NationalityType.Jp &&
      state.setting.accommodation.requiredFuriganaInput
    ) {
      if (!value.trim()) {
        setIsFormValidRequiredFields(false)
      }
    }
    setAccommodationInfo(accomInfo => ({ ...accomInfo, [name]: value }))
  }

  const convertToNameKatakana = text => {
    return text.replace(/[\u3041-\u3096]/g, t => String.fromCharCode(t.charCodeAt(0) + 96))
  }

  const submit = async () => {
    setIsEmailValid(true)
    setIsPhoneValid(true)
    setIsPostCodeValid(true)
    setIsFirstNameValid(true)
    setIsLastNameValid(true)
    const isValid = checkValidateFormRequired()
    setIsFormValidRequiredFields(isValid)
    if (isValid) {
      const checkEmail = accommodationInfo.mail ? checkEmailValid(accommodationInfo.mail) : true
      const checkPhone = checkNumberOnly(accommodationInfo.phoneNumber)

      const checkLastName = !(accommodationInfo.nationality === NationalityType.Jp && lang === 'ja')
        ? checkOnlyAnphabet(accommodationInfo.lastName)
        : true
      const checkFirstName = !(accommodationInfo.nationality === NationalityType.Jp && lang === 'ja')
        ? checkOnlyAnphabet(accommodationInfo.firstName)
        : true

      setIsEmailValid(checkEmail)
      setIsPhoneValid(checkPhone)
      setIsFirstNameValid(checkFirstName)
      setIsLastNameValid(checkLastName)

      const isValidForm = checkEmail && checkPhone && checkFirstName && checkLastName
      if (!isValidForm) {
        // set the name of the error message
        let scrollName = ''
        switch (true) {
          case !checkLastName:
            scrollName = 'lastName'
            break
          case !checkFirstName:
            scrollName = 'firstName'
            break
          case !checkPhone:
            scrollName = 'phoneNumber'
            break
          case !checkEmail:
            scrollName = 'mail'
            break
          default:
            // scroll to top
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
            break
        }
        if (scrollName) {
          // get element by name
          const scrollErrorName = document.getElementsByName(scrollName)
          // Scroll to target element
          scrollErrorName[0]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
        return
      }
      if (accommodationInfo.hasContactForAllGuest) {
        accommodationInfo.accompany = []
      }
      saveState({ ...state, accommodationInfo })

      history.push({ pathname: '/self-checkin/checkin-customize' })
    }
  }

  const autoCompleteWithGuestInfo = (
    paxTotal: number,
    guestInfo: Partial<{
      name: string
      nameKana: string
      nationality: 'JPN' | 'NJP'
      gender: 'M' | 'F'
      postCode: string
      address: string
      mail: string
      phoneNumber: string
      dateOfBirth: string
      company: string
      occupation: string
      otherOccupation: string
    }>,
  ) => {
    const isJapanese = lang === LanguageType.ja
    const [guestFirstName, guestLastName] = convertToSeparatedName({ name: guestInfo.name || '', isJapanese })
    const guestNameKana = convertToNameKatakana(guestInfo.nameKana || '')
    const [guestFirstNameKana, guestLastNameKana] = convertToSeparatedName({ name: guestNameKana })

    const occupation = !guestInfo.occupation
      ? OccupationType.Employee
      : Object.values(OccupationType).includes(guestInfo.occupation as OccupationType)
      ? guestInfo.occupation
      : OccupationType.Other
    const otherOccupation = occupation === OccupationType.Other ? guestInfo.otherOccupation : ''
    const company = isEmployee(occupation) ? guestInfo.company : ''

    const defaultNationality = lang === LanguageType.ja ? NationalityType.Jp : NationalityType.Njp
    const autoCompletedNationality = guestInfo.nationality === 'JPN' ? NationalityType.Jp : NationalityType.Njp

    setAccommodationInfo({
      ...accommodationInfo,
      ...{
        nationality: guestInfo.nationality ? autoCompletedNationality : defaultNationality,
        firstName: guestFirstName,
        lastName: guestLastName,
        firstNameFurigana: guestFirstNameKana,
        lastNameFurigana: guestLastNameKana,
        dateOfBirth: guestInfo.dateOfBirth || '',
        gender: guestInfo.gender || GenderType.Male,
        phoneNumber: guestInfo.phoneNumber && guestInfo.phoneNumber !== '0' ? guestInfo.phoneNumber : '',
        mail: guestInfo.mail || '',
        occupation,
        otherOccupation,
        company,
        postCode: guestInfo.postCode || '',
        address: guestInfo.address || '',
        accompany: Array.from(new Array(paxTotal - 1)).map(() => {
          return { id: uuidv4(), lastName: '', firstName: '', name: '' }
        }),
      },
    })
  }

  const getReservationDetail = useCallback((selectReservation: Required<SelfCheckInState>['selectReservation']) => {
    const pickedReservation = selectReservation.selectedReservations[0]
    const hasGuestInfo =
      pickedReservation.guestName ||
      pickedReservation.guestNameKana ||
      (pickedReservation.guestTel && pickedReservation.guestTel !== '0') ||
      pickedReservation.guestMailAddr ||
      pickedReservation.guestAddr

    const hasMemberInfo =
      pickedReservation.memberName ||
      pickedReservation.memberNameKana ||
      pickedReservation.memberNationality ||
      pickedReservation.memberGender ||
      pickedReservation.memberPostalCode ||
      pickedReservation.memberAddress ||
      pickedReservation.memberEmail ||
      (pickedReservation.memberTelephone && pickedReservation.memberTelephone !== '0') ||
      pickedReservation.memberBirthDate ||
      pickedReservation.memberCompany ||
      pickedReservation.memberOccupation ||
      pickedReservation.memberOtherOccupation

    const paxTotal = selectReservation.paxTotal || 1

    if (hasMemberInfo) {
      autoCompleteWithGuestInfo(paxTotal, {
        name: pickedReservation.memberName,
        nameKana: pickedReservation.memberNameKana,
        nationality: pickedReservation.memberNationality,
        gender: pickedReservation.memberGender,
        postCode: pickedReservation.memberPostalCode,
        address: pickedReservation.memberAddress,
        mail: pickedReservation.memberEmail,
        phoneNumber: pickedReservation.memberTelephone,
        dateOfBirth: pickedReservation.memberBirthDate,
        company: pickedReservation.memberCompany,
        occupation: pickedReservation.memberOccupation,
        otherOccupation: pickedReservation.memberOtherOccupation,
      })
    } else if (hasGuestInfo) {
      autoCompleteWithGuestInfo(paxTotal, {
        name: pickedReservation.guestName,
        nameKana: pickedReservation.guestNameKana,
        phoneNumber: pickedReservation.guestTel,
        mail: pickedReservation.guestMailAddr,
        address: pickedReservation.guestAddr,
      })
    } else {
      autoCompleteWithGuestInfo(paxTotal, {
        name: pickedReservation.userName,
        nameKana: pickedReservation.userNameKana,
        phoneNumber: pickedReservation.userTel,
        mail: pickedReservation.userMailAddr,
        address: pickedReservation.userAddr,
      })
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!state?.hotelId) {
      history.replace({ pathname: '/dashboard' })
      return
    }
    if (state.selectReservation?.hasSmartCheckin) {
      saveState({
        ...state,
        accommodationInfo: parseAccommodationFromCheckin(
          state.selectReservation.selectedReservations[0] as SearchReservationTypeWhenCheckedIn,
        ),
      })
      history.replace({ pathname: '/self-checkin/checkin-customize' })
      return
    }
    setIsInitializeComponent(true)
    if (state.accommodationInfo) {
      setAccommodationInfo(state.accommodationInfo)
    } else if (state.selectReservation) {
      getReservationDetail(state.selectReservation)
    }

    const addValidateProps: string[] = []
    if (state.setting.accommodation.requiredAccompanyNameInput) {
      addValidateProps.push('accompany')
    }
    if (state.setting.accommodation.requiredBirthDateInput) {
      addValidateProps.push('dateOfBirth')
    }
    if (state.setting.accommodation.requiredGenderInput) {
      addValidateProps.push('gender')
    }
    if (state.setting.accommodation.requiredTelephoneInput) {
      addValidateProps.push('phoneNumber')
    }
    if (state.setting.accommodation.requiredEmailInput) {
      addValidateProps.push('mail')
    }
    if (state.setting.accommodation.requiredAddressInput) {
      addValidateProps.push('address')
    }
    if (addValidateProps.length) {
      setListValidateProps([...listValidateProps, ...addValidateProps])
    }
  }, [])

  if (!isInitializeComponent) {
    return <></>
  }
  return (
    <SelfCheckinLayout>
      <div css={containerStyle}>
        <SelfCheckinHeader goToPreviousPage={history.goBack} title={t('Enter check-in information')} />
        <div css={mainStyle}>
          <AccommodationForm
            accommodationInfo={accommodationInfo}
            onChange={onChangeAccommodationInfo}
            requiredAccompanyNameInput={state.setting.accommodation.requiredAccompanyNameInput}
            requiredFuriganaInput={state.setting.accommodation.requiredFuriganaInput}
            requiredBirthDateInput={state.setting.accommodation.requiredBirthDateInput}
            requiredGenderInput={state.setting.accommodation.requiredGenderInput}
            requiredTelephoneInput={state.setting.accommodation.requiredTelephoneInput}
            requiredEmailInput={state.setting.accommodation.requiredEmailInput}
            requiredAddressInput={state.setting.accommodation.requiredAddressInput}
            isFormValidRequiredFields={isFormValidRequiredFields}
            isEmailValid={isEmailValid}
            isPhoneValid={isPhoneValid}
            isPostCodeValid={isPostCodeValid}
            isFirstNameValid={isFirstNameValid}
            isLastNameValid={isLastNameValid}
          />
        </div>
        <SelfCheckinFooter isDisabled={isDisabled} goToNextPage={submit} isNext={'next'} />
      </div>
    </SelfCheckinLayout>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  backgroundColor: '#F2F2F2',
  '*': {
    fontFamily: 'Noto Sans JP',
    color: '#272727',
  },
})

const mainStyle = css({
  padding: '102px 32px',
  minHeight: '100vh',
})
