import React, { useState, useContext, useEffect, useMemo } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { CheckinTabContainer } from '@/components/organisms/settings/checkin-tab-container'
import { AccountContext } from '@/contexts/account'
import { useHistory } from 'react-router-dom'
import * as Api from '@/apis/aipass'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'
import { ToggleButton } from '@/components/molecules/settings/toggle-button'
import { TimeSelectionIndex } from '@/models/custom-checkin-time-selection'
import { EditFooter } from '@/components/organisms/edit-footer'
import { useDraggableDom } from '@/components/molecules/settings/use-draggable-dom'
import { ContainerHeader } from '@/components/molecules/settings/container-header'
import { TableBody, TableHeader, TableRow } from '@/components/atoms/settings/table'
import { useErrorHandler } from '@/hooks/use-error-handler'

export const TimeSelection: React.FC = () => {
  const [timeSelections, setTimeSelections] = useState<TimeSelectionIndex[]>([])
  const [prevTimeSelections, setPrevTimeSelections] = useState<TimeSelectionIndex[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [deleteIds, setDeleteIds] = useState<string[]>([])
  const [isChangeOrder, setIsChangeOrder] = useState<boolean>(false)

  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const history = useHistory()
  const { errorHandler } = useErrorHandler()

  const changeDisplayOrder = newList => {
    setTimeSelections(newList)
    setIsChangeOrder(true)
  }
  const { dragStart, dragOver, dragDrop } = useDraggableDom({ list: timeSelections, onChange: changeDisplayOrder })

  const toggleViewStatus = async (changedItem: TimeSelectionIndex) => {
    try {
      setIsLoading(true)
      await Api.updateTimeSelectionViewState(changedItem.id, !changedItem.isVisible)
      await fetchCustomCheckin()
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchCustomCheckin = async () => {
    const res = await Api.fetchTimeSelection()
    setTimeSelections(res || [])
    setPrevTimeSelections(res || [])
  }

  const doUpdateList = async () => {
    try {
      setIsLoading(true)
      if (isChangeOrder) {
        await Api.updateTimeSelectionDisplayOrder(timeSelections.map(ts => ts.id))
      }
      if (deleteIds.length) {
        await Api.deleteTimeSelection(deleteIds)
      }
      await doReload(true)
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  const doReload = async (isFetch: boolean) => {
    setDeleteIds([])
    setIsChangeOrder(false)
    if (isFetch) {
      await fetchCustomCheckin()
    } else {
      setTimeSelections(prevTimeSelections)
    }
  }

  useEffect(() => {
    if (account && account.hotel) {
      fetchCustomCheckin()
    }
  }, [account])

  const showFooter = useMemo((): boolean => isChangeOrder || !!deleteIds.length, [isChangeOrder, deleteIds])

  const footerContent = showFooter ? <EditFooter onSave={() => doUpdateList()} onCancel={() => doReload(false)} /> : undefined
  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <TabContainer tabComponent={<CheckinTabContainer currentTab="Time selection" />} isShowFooter={showFooter}>
        <ContainerHeader
          onClickAddButton={() => history.push('/setting/checkin/time-selection/new')}
          style={{ flexDirection: 'row-reverse', padding: '0 16px 0 32px' }}
        />
        <TableHeader>
          <div css={titleStyle}>{t('Title')}</div>
          <div css={spaceTypeStyle}>{t('Space type')}</div>
          <div css={keywordGroupStyle}>{t('keyword group')}</div>
          <div css={keywordsToDisplayStyle}>{t('Keywords to display')}</div>
          <div css={detailColStyle} />
        </TableHeader>
        <TableBody>
          {timeSelections
            .filter(timeSelection => !deleteIds.includes(timeSelection.id))
            .map(timeSelection => (
              <TableRow
                key={timeSelection.id}
                id={timeSelection.id}
                draggable={true}
                onDragStart={dragStart}
                onDragOver={dragOver}
                onDrop={dragDrop}
                onClick={() => history.push(`/setting/checkin/time-selection/${timeSelection.id}`)}
              >
                <div css={titleStyle}>{timeSelection.title}</div>
                <div css={spaceTypeStyle}>{timeSelection.spaceTypeName}</div>
                <div css={keywordGroupStyle}>{timeSelection.planKeyWordGroupName || t('All reservations')}</div>
                <div css={keywordsToDisplayStyle}>{timeSelection.planKeywords.map(keyword => keyword.keyword).join(', ') || '-'}</div>
                <div css={detailColStyle}>
                  <div css={listButtonStyle}>
                    <ToggleButton value={timeSelection.isVisible} onChange={() => toggleViewStatus(timeSelection)} />
                    <DeleteIcon style={{ marginLeft: 25 }} onClick={() => setDeleteIds([...deleteIds, timeSelection.id])} />
                  </div>
                </div>
              </TableRow>
            ))}
        </TableBody>
      </TabContainer>
    </SettingsLayout>
  )
}

const titleStyle = css({ marginLeft: 62, minWidth: 150, maxWidth: 150 })
const spaceTypeStyle = css({ minWidth: 200, maxWidth: 200 })
const keywordGroupStyle = css({ minWidth: 200, maxWidth: 200 })
const keywordsToDisplayStyle = css({ flexGrow: 1 })
const detailColStyle = css({ minWidth: 128, maxWidth: 128, margin: '0 32px' })

const listButtonStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
})
