import { SettingsLayout } from '@/components/layouts/settings-layout'
import React from 'react'
import { TabContainer } from '../tab-container'
import { CheckinTabContainer } from '../checkin-tab-container'
import { useForm } from 'react-hook-form'
import {
  DayOfApplicableType,
  DayOfUseType,
  StockPerReservationType,
  TimeSelectionDetail,
  TimeSelectionInput,
} from '@/models/custom-checkin-time-selection'
import { EditFooter } from '../../edit-footer'
import { useHistory } from 'react-router-dom'
import { DetailForm } from './detail-form'
import { SpaceDetailType } from '@/models/guest-app/asset'
import { KeywordGroup } from '@/models/reservation/keyword-group'

interface FormTemplateProps {
  spaceAssets: SpaceDetailType[]
  keyWordGroups: KeywordGroup[]
  onSubmit: (data: TimeSelectionInput) => void
  isLoading: boolean
  detail?: TimeSelectionDetail
}

export const FormTemplate: React.FC<FormTemplateProps> = ({ onSubmit, isLoading, spaceAssets, keyWordGroups, detail }) => {
  const history = useHistory()
  const useFormReturn = useForm<TimeSelectionInput>({
    defaultValues: {
      spaceId: detail?.spaceId || '',
      title: detail?.title || '',
      description: detail?.description || '',
      rawDescription: detail?.rawDescription || '',
      planKeyWordGroupId: detail?.planKeyWordGroupId || '',
      planKeywords: detail?.planKeywords || [],
      dayOfApplicable: detail?.dayOfApplicable || DayOfApplicableType.EveryDay,
      dayOfUse: detail?.dayOfUse || DayOfUseType.AppointedDay,
      stockPerReservation: detail?.stockPerReservation || StockPerReservationType.Single,
      isRequired: detail ? detail.isRequired : true,
    },
  })
  const {
    formState: { isValid },
    handleSubmit,
  } = useFormReturn

  const footerContent = <EditFooter isDisabledSave={!isValid} onCancel={history.goBack} onSave={handleSubmit(onSubmit)} />
  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <TabContainer tabComponent={<CheckinTabContainer currentTab="Time selection" />}>
        <DetailForm useFormReturn={useFormReturn} spaceAssets={spaceAssets} keyWordGroups={keyWordGroups} detail={detail} />
      </TabContainer>
    </SettingsLayout>
  )
}
