import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { createTimeSelection, fetchTimeSelectionDetail, getAssetList, getKeywordGroupSetting, updateTimeSelection } from '@/apis/aipass'
import { isSpaceAsset, SpaceAsset, SpaceDetailType } from '@/models/guest-app/asset'
import { KeywordGroup } from '@/models/reservation/keyword-group'
import { TimeSelectionInput } from '@/models/custom-checkin-time-selection'
import { LoadingFull } from '@/components/molecules/loading-full'
import { FormTemplate } from '@/components/organisms/settings/timeSelection/form-template'

export const TimeSelectionDetailPage: React.FC = () => {
  const [spaceAssets, setSpaceAssets] = useState<SpaceDetailType[]>([])
  const [keywordGroups, setKeywordGroups] = useState<KeywordGroup[]>([])
  const [timeSelection, setTimeSelection] = useState<any>()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { id: timeSelectionId } = useParams<{ id?: string }>()

  const history = useHistory()

  const fetchSpaceAssets = () => {
    getAssetList().then(assets => {
      const spaceAssets = assets.filter(asset => isSpaceAsset(asset)) as SpaceAsset[]
      setSpaceAssets(spaceAssets.flatMap(asset => asset.details))
    })
  }

  const fetchKeyWordGroups = async () => {
    getKeywordGroupSetting().then(res => {
      setKeywordGroups(res)
    })
  }

  const saveTimeSelection = async (data: TimeSelectionInput) => {
    setIsLoading(true)
    const submitData = {
      ...data,
      planKeywords: data.planKeywords.filter(({ keyword }) => keyword !== 'all').map(planKeyword => planKeyword.keyword),
    }
    if (timeSelectionId) {
      await updateTimeSelection({ id: timeSelectionId, ...submitData })
    } else {
      await createTimeSelection(submitData)
    }
    history.push('/setting/checkin/time-selection')
  }

  const getTimeSelectionDetail = async (id: string) => {
    setIsLoading(true)
    const res = await fetchTimeSelectionDetail(id)
    setTimeSelection(res)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchSpaceAssets()
    fetchKeyWordGroups()
  }, [])

  useEffect(() => {
    if (timeSelectionId) {
      getTimeSelectionDetail(timeSelectionId)
    }
  }, [timeSelectionId])

  return !isLoading ? (
    <FormTemplate
      onSubmit={saveTimeSelection}
      isLoading={isLoading}
      spaceAssets={spaceAssets}
      keyWordGroups={keywordGroups}
      detail={timeSelection}
    />
  ) : (
    <LoadingFull isLoading={isLoading} />
  )
}
